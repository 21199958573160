"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContainerDate = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _templateObject;
const ContainerDate = _styled.default.span(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  font-weight: 500;\n  color: ", ";\n  margin-left: 0.4em;\n  display: block;\n  font-size: 1.3rem;\n  white-space: nowrap;\n"])), props => props.color);
exports.ContainerDate = ContainerDate;