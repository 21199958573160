import { UseFormReturnType } from "@mantine/form";
import React from "react";
import { ShareInputTitle, ShareInputWrapper, ShareValue } from "./GapFundingCalculator.styled";
import { Grid, NumberInput, RingProgress, Slider, Text, createStyles } from "@mantine/core";
import { useHover } from "@mantine/hooks";

type ShareInputProps = {
  title: string;
  form: UseFormReturnType<any, (values: any) => any>;
  fieldName: string;
  labels: {
    [key: string]: string;
  };
  color: string;
  readOnly: boolean;
  disabled: boolean;
};

type ShareValues = {
  [key: string]: number;
};

export function ShareInput({ form, title, fieldName, labels, color, readOnly, disabled }: ShareInputProps) {
  const handleChangeValue = (key: string, value: number) => {
    form.setFieldValue(`${fieldName}.${key}`, value);
  };

  const values: ShareValues = form.values[fieldName];

  if (!values) {
    return null;
  }

  const sum = Object.values(values).reduce((sum, value) => sum + value, 0);

  return (
    <ShareInputWrapper>
      <ShareInputTitle color={color}>{title}</ShareInputTitle>
      <Grid sx={{ marginTop: "4px" }}>
        {form.errors[fieldName] && (
          <Grid.Col span={12}>
            <Text color="red">{form.errors[fieldName]}</Text>
          </Grid.Col>
        )}
        {Object.entries(values).map(([key, value]) => (
          <Grid.Col sm={2} xs={6} key={key}>
            <Text size={"sm"} weight={500} sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
              {labels[key]}
            </Text>
            {!readOnly && (
              <NumberInput
                min={0}
                max={100}
                mb={"xs"}
                {...form.getInputProps(`${fieldName}.${key}`)}
                required
                parser={(value) => value?.replace("%", "")}
                formatter={(value) => (!Number.isNaN(parseFloat(value!)) ? `${value.replace("%", "")}%` : "")}
                disabled={disabled}
              />
            )}
            {readOnly && <ShareValue>{form.values[fieldName][key]}%</ShareValue>}
            <HoverSlider value={value} k={key} onChange={handleChangeValue} readOnly={readOnly} />
          </Grid.Col>
        ))}
        <Grid.Col sm={2} xs={6}>
          <RingProgress
            mt="xs"
            size={62}
            thickness={5}
            label={
              <Text size="xs" align="center">
                {sum}%
              </Text>
            }
            sections={[
              { value: sum, color: sum === 100 ? "#42aa5055" : "#cc0505bb" },
              { value: Math.max(0, 100 - sum), color: "#ddd" },
            ]}
            sx={{ margin: "auto" }}
          />
        </Grid.Col>
      </Grid>
    </ShareInputWrapper>
  );
}

const useSliderStyles = createStyles((theme) => ({
  markLabel: { fontSize: theme.fontSizes.xs, marginBottom: 5, marginTop: 4 },
}));

type HoverSliderProps = {
  value: number;
  k: string;
  onChange: (key: string, value: number) => void;
  readOnly: boolean;
};

function HoverSlider({ value, k, onChange, readOnly }: HoverSliderProps) {
  const { hovered, ref } = useHover();
  const { classes } = useSliderStyles();

  return (
    <Slider
      classNames={classes}
      value={value}
      min={0}
      step={5}
      label={null}
      onChange={(val) => onChange(k, val)}
      ref={ref}
      styles={{
        thumb: {
          transition: "opacity 150ms ease",
          opacity: hovered ? 1 : 0,
        },

        dragging: {
          opacity: 1,
        },
      }}
      disabled={readOnly}
    />
  );
}
