"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MarkdownWrapper = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _templateObject;
const MarkdownWrapper = _styled.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  p {\n    margin-top: 0;\n    margin-bottom: 0.8rem;\n  }\n\n  ul, ol {\n    margin-top: 0;\n    margin-bottom: 0.8rem;\n  }\n"])));
exports.MarkdownWrapper = MarkdownWrapper;