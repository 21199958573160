import type { ReportData, ReportSkeleton } from "@api/reports/output/output.types";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ReportNav, ReportNavItem, ReportNavSections, ReportNavTitle, ReportSectionItem } from "./ReportMenu.styled";
import { chapterHasContent, sectionHasContent } from "../utils";

type ReportMenuProps = {
  report?: ReportData | ReportSkeleton;
  isLoading: boolean;
  sectionId: string | null;
  onSectionSelect: (newChapterId: string, smooth?: boolean) => void;
  pageId: string;
};

function ReportMenu({ report, isLoading, sectionId, onSectionSelect, pageId }: ReportMenuProps) {
  const [selectedChapterId, setSelectedChapterId] = useState<string>("");
  const navRef = useRef<HTMLElement>(null);

  const handleChapterClick = (chapterId: string) => {
    setSelectedChapterId(chapterId);
    const chapter = report.chapters.find((chapter) => chapter.id === chapterId);
    if (chapter) {
      onSectionSelect(chapter.sections[0].id, false);
    }
  };

  useEffect(() => {
    if (sectionId && report) {
      const chapter = report.chapters.find((chapter) => chapter.sections.find((section) => section.id === sectionId));
      if (chapter) {
        setSelectedChapterId(chapter.id);
      }
    }
  }, [sectionId, report]);

  useLayoutEffect(() => {
    if (!navRef.current) {
      return;
    }
    const listItem = navRef.current.querySelector("li.selected li.selected") as HTMLLIElement;
    if (listItem) {
      makeElementVisible(listItem);
    }
  }, [sectionId, report]);

  const pageChapters = report?.chapters
    .filter((chapter) => chapter.pageId === pageId)
    .filter((chapter) => isLoading || chapterHasContent("screen")(chapter));

  return (
    <ReportNav ref={navRef}>
      {report && pageChapters.length > 0 && (
        <ul>
          {pageChapters.map((chapter, idx) => (
            <ReportNavItem key={chapter.id} className={selectedChapterId === chapter.id ? "selected" : ""}>
              <ReportNavTitle onClick={() => handleChapterClick(chapter.id)}>{chapter.title}</ReportNavTitle>
              <ReportNavSections isLast={idx === pageChapters.length - 1}>
                {chapter.sections.filter(sectionHasContent("screen")).map((section) => (
                  <ReportSectionItem
                    key={section.title}
                    className={sectionId === section.id ? "selected" : ""}
                    onClick={() => onSectionSelect(section.id)}
                    selectedColor={report.project.app.configuration.theme.colors.main}
                  >
                    {section.title}
                  </ReportSectionItem>
                ))}
              </ReportNavSections>
            </ReportNavItem>
          ))}
        </ul>
      )}
    </ReportNav>
  );
}

export default ReportMenu;

function makeElementVisible(listItem: HTMLLIElement) {
  const parentList = listItem.parentElement;
  const parentListRect = parentList.getBoundingClientRect();
  const listItemRect = listItem.getBoundingClientRect();
  if (listItemRect.top < parentListRect.top) {
    // Element is above visible area, scroll up
    parentList.scrollTop -= parentListRect.top - listItemRect.top;
  } else if (listItemRect.bottom > parentListRect.bottom) {
    // Element is below visible area, scroll down
    parentList.scrollTop += listItemRect.bottom - parentListRect.bottom;
  }
}
