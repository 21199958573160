import React, { useEffect } from "react";
import { CoverFooter, CoverLogo, PdfExtraPage, PrintCover, PrintLayout } from "./PrintReport.styled";
import { Footer } from "./components/Section.styled";
import type { ReportData } from "@api/reports/output/output.types";
import { MainContent } from "./Report.styled";
import { FetchPageBody, ThemeContext } from "./Report";
import PrintChapter from "./components/PrintChapter";
import { chapterHasContent } from "./utils";
import { useApi } from "../../hooks/useApi";

type PrintDashboardProps = {
  reportId: string;
  geoId: string;
  customGeoIds: string[];
  exclude: string[];
  filters: string[];
};

const LAYOUT = "print";

export default function PrintReport({ reportId, geoId, customGeoIds, filters, exclude }: PrintDashboardProps) {
  const {
    data: report,
    // error,
    loading: isLoading,
    callApi: fetchPage,
  } = useApi<ReportData, { reportId: string; geoId: string }, FetchPageBody>(
    "/reports/:reportId/output/:geoId",
    "post"
  );

  useEffect(() => {
    const data = { customGeoIds, exclude, selection: [], filters };
    void fetchPage({ params: { reportId, geoId }, data });
  }, [fetchPage, reportId, geoId, customGeoIds, exclude, filters]);

  const chapters = report?.chapters.filter(chapterHasContent("print")).sort((a, b) => {
    return (
      report.pages.find((page) => page.id === a.pageId).order - report.pages.find((page) => page.id === b.pageId).order
    );
  });

  const appTheme = report?.project.app.configuration?.theme;
  const mainColor = appTheme?.colors.main;

  const content = report && !isLoading && (
    <ThemeContext.Provider value={appTheme}>
      <MainContent layout={LAYOUT}>
        {chapters.map((chapter) => (
          <PrintChapter chapter={chapter} key={chapter.id} report={report}></PrintChapter>
        ))}
      </MainContent>
    </ThemeContext.Provider>
  );

  return (
    <PrintLayout>
      {report && (
        <PrintCover color={mainColor}>
          <CoverLogo color={mainColor}>
            <img src={appTheme.logo} alt="logo" />
          </CoverLogo>
          <h1>{report.title}</h1>
          <h2>{report.geography.name}</h2>
          <CoverFooter color={mainColor}>
            {report.author ? report.author + " • " : " "}
            {new Date().toLocaleDateString("en-US", { month: "long", year: "numeric" })}
          </CoverFooter>
        </PrintCover>
      )}
      {report?.output?.pdf.pages
        .filter((page) => page.position === "after_cover")
        .map((page) => (
          <PdfExtraPage color={mainColor} key={page._id}>
            <img src={page.fileUrl} alt="" />
            <Footer>{`${report.title} - ${report.geography.name}`}</Footer>
          </PdfExtraPage>
        ))}

      {content}

      {report?.output?.pdf.pages
        .filter((page) => page.position === "document_end")
        .map((page) => (
          <PdfExtraPage color={mainColor} key={page._id}>
            <img src={page.fileUrl} alt="" />
          </PdfExtraPage>
        ))}
    </PrintLayout>
  );
}
