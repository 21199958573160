import React from "react";
import { HeaderContent, HeaderLogo, MainHeader, MainHeaderWrapper } from "./ScreenHeader.styled";
import { useNavigate } from "react-router-dom";
import type { ProjectWithReportsOut } from "@api/projects/projects.types";

type IntroductionHeaderProps = {
  project: ProjectWithReportsOut;
};

export default function IntroductionHeader({ project }: IntroductionHeaderProps) {
  const navigate = useNavigate();
  const appConfig = project.app.configuration;

  if (appConfig.landingPage.behavior !== "portal") {
    return null;
  }

  const theme = appConfig.theme;
  const title = appConfig.landingPage.configuration.title;

  return (
    <>
      <MainHeaderWrapper color={theme.colors.main} withNav={false}>
        <MainHeader>
          <HeaderLogo onClick={() => navigate("/")}>
            {theme.logo && <img src={`${theme.logo}`} alt="HR&amp;A"></img>}
          </HeaderLogo>
          <HeaderContent>{title && <h1>{title}</h1>}</HeaderContent>
        </MainHeader>
      </MainHeaderWrapper>
    </>
  );
}
