import type { ChartData, ReportData, SectionData } from "@api/reports/output/output.types";
import React from "react";
import { Page } from "./PrintSection.styled";
import { SectionChartGrid, ChartContainerWrapper, Footer, SectionSummary, SectionTitle } from "./Section.styled";
import { ChartContainer, Markdown } from "@hra/hna-charts";

type PrintSectionProps = {
  section: SectionData;
  report: ReportData;
};

const LAYOUT = "print";

export default function PrintSection({ section, report }: PrintSectionProps) {
  const appTheme = report.project.app.configuration.theme;

  let pageCharts: ChartData[][] = [];
  for (const chart of section.charts) {
    // @ts-ignore
    if (chart.output && !chart.output.includes("all") && !chart.output.includes("pdf")) {
      continue;
    }
    const lastPage = pageCharts.at(-1);
    if (lastPage?.length === 1) {
      if (!chart.spanRow && !lastPage[0].spanRow) {
        lastPage.push(chart);
        continue;
      }
    }
    pageCharts.push([chart]);
  }

  pageCharts = pageCharts.map((charts) => {
    if (charts.length === 1) {
      charts[0].spanRow = !charts[0].component.endsWith("_map");
    }
    return charts.map((chart) => {
      if (chart.component.endsWith("_map")) {
        return chart;
      }
      if (chart.spanRow) {
        //@ts-ignore
        chart.aspectRatio = "8:3";
      } else {
        //@ts-ignore
        chart.aspectRatio = "5:3";
      }
      return chart;
    });
  });

  return (
    <>
      {pageCharts.length === 0 && section.summary.text && (
        <Page color={appTheme.colors.main}>
          <SectionTitle color={appTheme.colors.main} layout={LAYOUT}>
            {section.title}
          </SectionTitle>
          {section.summary.text && (
            <SectionSummary layout={LAYOUT}>
              <Markdown text={section.summary.text} />
            </SectionSummary>
          )}
          <Footer>{`${report.title} - ${report.geography.name}`}</Footer>
        </Page>
      )}
      {pageCharts.map((charts, idx) => {
        return (
          <Page key={idx} color={appTheme.colors.main}>
            {idx === 0 && (
              <>
                <SectionTitle color={appTheme.colors.main} layout={LAYOUT}>
                  {section.title}
                </SectionTitle>
                {section.summary.text && (
                  <SectionSummary layout={LAYOUT}>
                    <Markdown text={section.summary.text} />
                  </SectionSummary>
                )}
              </>
            )}
            <SectionChartGrid layout={LAYOUT} isFirstPage={idx === 0}>
              {charts.map((chart) => (
                <ChartContainerWrapper spanRow={chart.spanRow} key={chart.id} layout={LAYOUT}>
                  <ChartContainer chart={chart} layout={LAYOUT} report={report} theme={appTheme} parentFilter={{}} />
                </ChartContainerWrapper>
              ))}
            </SectionChartGrid>
            <Footer>{`${report.title} - ${report.geography.name}`}</Footer>
          </Page>
        );
      })}
    </>
  );
}
