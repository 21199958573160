import type { ReportData, WidgetData } from "@api/reports/output/output.types";
import React, { useEffect, useState } from "react";
import { WidgetProps } from "../types";
import { GeographyComparatorPanel } from "./GeographyComparator.styled";
import { MantineProvider, MultiSelect, SelectItem } from "@mantine/core";
import { GeographyInfo } from "@api/main.types";
import { useApi } from "../../../../hooks/useApi";

type GeographyComparatorProps = Omit<WidgetProps, "widget"> & {
  widget: Omit<WidgetData, "componentProps"> & {
    componentProps: {
      subset: "map_sorroundings" | "map_segments";
    };
  };
};

export default function GeographyComparator({
  widget,
  theme,
  report,
  setCharts,
  customGeoIds,
}: GeographyComparatorProps) {
  const [geoIds, setGeoIds] = useState<string[]>(customGeoIds || []);

  const { data: geographies, callApi: fetchGeographies } = useApi<
    any, // TODO: Change
    { reportId: string; geoId: string; subset: string }
  >(`/reports/:reportId/output/:geoId/geographies/:subset`);

  const {
    data: updatedReport,
    error: updateError, // TODO: Do something
    callApi: updateReport,
  } = useApi<ReportData, { reportId: string; geoId: string }, { selection: string[]; customGeoIds: string[] }>(
    `/reports/:reportId/output/:geoId`,
    "post"
  );

  useEffect(() => {
    void fetchGeographies({
      params: { reportId: report.id, geoId: report.geography.id, subset: widget.componentProps.subset },
    });
  }, [fetchGeographies, report, widget]);

  useEffect(() => {
    const chapter = report.chapters.find((chapter) =>
      chapter.sections.find((section) => section.widgets?.find((sectionWidget) => sectionWidget.id === widget.id))
    );
    const section = chapter?.sections.find((section) =>
      section.widgets?.find((sectionWidget) => sectionWidget.id === widget.id)
    );
    void updateReport({
      params: {
        reportId: report.id,
        geoId: report.geography.id,
      },
      data: {
        selection: [section.id],
        customGeoIds: geoIds,
      },
    });
  }, [report, geoIds, updateReport, widget]);

  useEffect(() => {
    if (updatedReport) {
      const uChapter = updatedReport.chapters.find((chapter) =>
        chapter.sections.find((section) => section.widgets?.find((sectionWidget) => sectionWidget.id === widget.id))
      );
      const uSection = uChapter?.sections.find((section) =>
        section.widgets?.find((sectionWidget) => sectionWidget.id === widget.id)
      );
      if (uSection) {
        setCharts(uSection.charts);
      }
    }
  }, [updatedReport, widget, setCharts]);

  let options: SelectItem[] = [];
  if (geographies?.[widget.componentProps.subset]) {
    options = geographies[widget.componentProps.subset].map((item: GeographyInfo) => ({
      value: item.id,
      label: item.name,
    }));
  }

  return (
    <MantineProvider
      theme={{
        colors: {
          // @ts-ignore
          blue: Array.from({ length: 10 }, (val, idx) => theme.colors.main),
        },
      }}
    >
      <GeographyComparatorPanel>
        <MultiSelect
          placeholder="Compare to..."
          data={options}
          value={geoIds}
          onChange={(values) => setGeoIds(values)}
          sx={{ minWidth: "300px" }}
        />
      </GeographyComparatorPanel>
    </MantineProvider>
  );
}
