"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useChartTooltip = useChartTooltip;
var _react = _interopRequireWildcard(require("react"));
var _lodash = _interopRequireDefault(require("lodash.findlast"));
var _core = require("@popperjs/core");
var _labelFormatter = require("../utils/label-formatter");
var _types = require("../charts/types");
var _chartOperations = require("../utils/chart-operations");
var _constants = require("../constants");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function useChartTooltip(chart, chartData) {
  let xCol = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "xCol";
  const {
    dataView,
    tooltipCol,
    seriesCol,
    tooltipFormat = _types.ChartQuantitativeFormat.NONE
  } = chart;
  const tooltipRef = _react.default.useRef(null);
  const col = chart[xCol];
  let instance = null;
  const showTooltip = (0, _react.useCallback)(function (el) {
    var _xLabel;
    let placement = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "auto-start";
    if (!tooltipRef.current) {
      return;
    }
    const elData = el.dataset;
    let label = elData.label;
    let value = elData.value;
    let xLabel = elData.xLabel;
    const format = elData.format || tooltipFormat;
    const {
      columns
    } = dataView;
    const seriesData = elData.series && chartData.find(data => data.seriesKey === elData.series);
    if (!seriesData && (label === undefined || value === undefined)) {
      return;
    }
    let point = elData.x && (0, _lodash.default)(seriesData.points, {
      [col]: elData.x
    });
    if (!point && (label === undefined || value === undefined)) {
      return;
    }
    if (label === undefined) {
      var _columns$seriesCol, _columns$seriesCol$ca, _columns$COL_GEO_ID;
      label = ((_columns$seriesCol = columns[seriesCol]) === null || _columns$seriesCol === void 0 ? void 0 : (_columns$seriesCol$ca = _columns$seriesCol.categories[seriesData.seriesKey]) === null || _columns$seriesCol$ca === void 0 ? void 0 : _columns$seriesCol$ca.label) || seriesData.seriesKey;
      if (seriesCol === _constants.COL_DATE) {
        label = (0, _chartOperations.formatDate)(seriesData.seriesKey);
      } else if (seriesCol === _constants.COL_REL && (_columns$COL_GEO_ID = columns[_constants.COL_GEO_ID]) !== null && _columns$COL_GEO_ID !== void 0 && _columns$COL_GEO_ID.categories[point[_constants.COL_GEO_ID]]) {
        label = columns[_constants.COL_GEO_ID].categories[point[_constants.COL_GEO_ID]].label;
      }
    }
    if (value === undefined) {
      value = point[tooltipCol];
    }
    if (format in _labelFormatter.QuantitativeFormatter && _labelFormatter.QuantitativeFormatter[format](value) === "") {
      return;
    }
    if (xLabel === undefined && point) {
      var _columns$col, _columns$col$categori;
      xLabel = (_columns$col = columns[col]) === null || _columns$col === void 0 ? void 0 : (_columns$col$categori = _columns$col.categories[point[col]]) === null || _columns$col$categori === void 0 ? void 0 : _columns$col$categori.label;
      if (!xLabel) {
        if (col === _constants.COL_GEO_ID) {
          var _columns$COL_GEO_ID$c, _columns$COL_GEO_ID$c2;
          xLabel = ((_columns$COL_GEO_ID$c = columns[_constants.COL_GEO_ID].categories) === null || _columns$COL_GEO_ID$c === void 0 ? void 0 : (_columns$COL_GEO_ID$c2 = _columns$COL_GEO_ID$c[point[col]]) === null || _columns$COL_GEO_ID$c2 === void 0 ? void 0 : _columns$COL_GEO_ID$c2.label) || point[col];
        } else if (col === _constants.COL_DATE) {
          xLabel = (0, _chartOperations.formatDate)(point[col]);
        } else if (col === _constants.COL_YEAR) {
          xLabel = point[col];
        }
      }
    }
    tooltipRef.current.querySelector("h5").innerText = label;
    tooltipRef.current.querySelector("h5").style.background = elData.color;
    tooltipRef.current.querySelector("em").innerText = (_xLabel = xLabel) !== null && _xLabel !== void 0 ? _xLabel : "";
    tooltipRef.current.querySelector("span").innerText = (xLabel ? ": " : "") + (format in _labelFormatter.QuantitativeFormatter ? _labelFormatter.QuantitativeFormatter[format](value) : value);
    tooltipRef.current.style.display = "block";
    tooltipRef.current.classList.add("visible");
    if (instance) {
      instance.destroy();
    }
    instance = (0, _core.createPopper)(el, tooltipRef.current, {
      placement,
      modifiers: [{
        name: "offset",
        options: {
          offset: [0, 4]
        }
      }, {
        name: "eventListeners",
        enabled: false
      }]
    });
  }, [col, tooltipCol, dataView, tooltipFormat, chartData]);
  const hideTooltip = (0, _react.useCallback)(() => {
    if (!tooltipRef.current) {
      return;
    }
    if (instance) {
      instance.destroy();
    }
    tooltipRef.current.classList.remove("visible");
    tooltipRef.current.style.display = "none";
  }, []);
  return {
    tooltipRef,
    showTooltip,
    hideTooltip
  };
}