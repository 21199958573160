"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChartSourceDiv = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _templateObject;
const ChartSourceDiv = _styled.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  em {\n    font-weight: bold;\n    font-style: normal;\n  } \n  text-align: left;\n  font-size: 0.9em;\n  color: #888;\n\n  @media print {\n    font-size: 0.8em;\n  }\n"])));
exports.ChartSourceDiv = ChartSourceDiv;