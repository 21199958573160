"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseMapWrapper = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _templateObject;
const BaseMapWrapper = _styled.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  margin-bottom: 1rem;\n  margin-top: 1rem;\n\n  .tooltip-wrapper {\n    border-radius: 0.5rem;\n    overflow: hidden;\n    width: fit-content;\n\n    h5 {\n      padding: 4px 8px;\n    }\n\n    span {\n      padding: 0px 0px;\n    }\n  }\n\n  .mapboxgl-popup-content {\n    padding: 0;\n    background: white;\n    border: white 1px solid;\n    font-size: 1rem;\n  }\n"])));
exports.BaseMapWrapper = BaseMapWrapper;