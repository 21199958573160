"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BeeswarmChartGroup = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _templateObject;
const BeeswarmChartGroup = _styled.default.g(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  text {\n    font-size: 0.5em;\n    fill: #333;\n  }\n"])));
exports.BeeswarmChartGroup = BeeswarmChartGroup;