import styled from "@emotion/styled";

export const Page = styled.article<{ color: string }>`
  border-top: ${(props) => props.color} 1em solid;
  padding: 1.5em 2em;
  min-height: 816px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  page-break-before: always;
`;
