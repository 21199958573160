"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChartSvg = exports.ChartBox = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _templateObject, _templateObject2;
const ChartBox = _styled.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  width: 100%;\n  padding: 1em 0;\n  position: relative;\n"])));
exports.ChartBox = ChartBox;
const ChartSvg = _styled.default.svg(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  margin: auto;\n  margin-top: 1em;\n  width: ", ";\n  margin-right: ", ";\n  max-width: ", ";\n  height: auto;\n  aspect-ratio: ", ";\n\n  .x-axis,\n  .y-axis {\n    font-size: clamp(0.75rem, 1.1vw, 0.9rem);\n\n    line, path {\n      stroke: #888;\n    }\n\n    text {\n      fill: #666;\n    }\n  }\n\n  .small-label {\n    .x-axis, y-axis{\n      font-size: clamp(0.65rem, 0.8vw, 0.8rem);\n    }\n  }\n\n  @media print {\n    .x-axis,\n    .y-axis,\n    text {\n      font-size: 0.65rem !important;\n    }\n  }\n\n  .inside-label {\n    color: #fff;\n    font-family: inherit;\n    font-weight: 600;\n  }\n"])), props => props.verticalLegend ? "calc(100% - 140px)" : "100%", props => props.verticalLegend ? "140px" : "auto", props => props.spanRow ? "1024px" : "740px", props => props.aspectRatio);
exports.ChartSvg = ChartSvg;