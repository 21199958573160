import { Loader, MultiSelect, SelectItem } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useApi } from "../../../hooks/useApi";
import { useDebouncedValue } from "@mantine/hooks";
import type { SearchResult } from "@api/sources/search-index";

type GeographyAutocompleteProps = {
  label: string;
  placeholder?: string;
  sourceId: string;
  multiple?: boolean;
  maxSelectedValues?: number;
  onValue: (results: string[]) => void;
};

type SearchQuery = {
  term: string;
  mode: "autocomplete" | "geoIds";
};

const COL_GEO_ID = "geo_id";
const COL_GEO_NAME = "geo_name";

export default function GeographyAutocomplete(props: GeographyAutocompleteProps) {
  const { label, sourceId, placeholder, onValue } = props;
  let multiple = props.multiple;
  let maxSelectedValues = props.maxSelectedValues;
  if (multiple === undefined) {
    multiple = maxSelectedValues ? maxSelectedValues > 1 : true;
  } else if (!multiple) {
    maxSelectedValues = 1;
  }

  const [items, setItems] = useState<SelectItem[]>([]);
  const [term, setTerm] = useState("");
  const [searchTerm] = useDebouncedValue(term, 300);

  let {
    data: searchResults,
    setData: clearResults,
    loading: searching,
    callApi: searchGeography,
  } = useApi<SearchResult[], { sourceId: string }, SearchQuery>("/sources/:sourceId/search");

  useEffect(() => {
    if (searchTerm.length >= 2 && sourceId) {
      searchGeography({ params: { sourceId }, query: { term: searchTerm, mode: "autocomplete" } });
    }
  }, [searchTerm, sourceId, searchGeography]);

  const onChange = (values: string[]) => {
    setItems(values.map((value) => ({ value, label: value })));
    clearResults([]);
    onValue(values);
  };

  const results: SelectItem[] = searchResults
    ? searchResults.map((result) => ({
        label: `${result[COL_GEO_NAME]} (${result[COL_GEO_ID]})`,
        value: result[COL_GEO_ID],
        group: result.type,
      }))
    : [];

  results.push(...items);

  return (
    <MultiSelect
      placeholder={placeholder || "Search by name or geo id (fips)."}
      label={label}
      data={results}
      maxSelectedValues={maxSelectedValues}
      searchable
      filter={(_, selected) => !selected}
      maxDropdownHeight={250}
      onSearchChange={(value) => setTerm(value)}
      rightSection={searching ? <Loader size={16} /> : null}
      onChange={onChange}
      value={items.map((item) => item.value)}
      autoComplete="off"
      dropdownPosition="bottom"
    />
  );
}
