"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChartQuantitativeFormat = exports.ChartCategoricalFormat = void 0;
let ChartQuantitativeFormat;
exports.ChartQuantitativeFormat = ChartQuantitativeFormat;
(function (ChartQuantitativeFormat) {
  ChartQuantitativeFormat["THOUSANDS"] = "thousands";
  ChartQuantitativeFormat["MONEY"] = "money";
  ChartQuantitativeFormat["NUMBER"] = "number";
  ChartQuantitativeFormat["PERCENT"] = "percent";
  ChartQuantitativeFormat["PERCENT_DIFF"] = "percent_diff";
  ChartQuantitativeFormat["MONEY_THOUSANDS"] = "money_thousands";
  ChartQuantitativeFormat["PERCENT_SIGNED"] = "percent_signed";
  ChartQuantitativeFormat["NUMBER_SIGNED"] = "number_signed";
  ChartQuantitativeFormat["NONE"] = "none";
  ChartQuantitativeFormat["HIDE"] = "hide";
})(ChartQuantitativeFormat || (exports.ChartQuantitativeFormat = ChartQuantitativeFormat = {}));
let ChartCategoricalFormat;
exports.ChartCategoricalFormat = ChartCategoricalFormat;
(function (ChartCategoricalFormat) {
  ChartCategoricalFormat["NONE"] = "none";
  ChartCategoricalFormat["LABEL"] = "label";
})(ChartCategoricalFormat || (exports.ChartCategoricalFormat = ChartCategoricalFormat = {}));