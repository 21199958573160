import React, { Dispatch, SetStateAction } from "react";
import type { ChartData, ReportData, WidgetData } from "@api/reports/output/output.types";
import { WidgetWrapper } from "./WidgetContainer.styled";
import { FilterState, WidgetProps } from "./types";
import { MantineProvider } from "@mantine/core";
import GapFundingCalculator from "./GapFundingCalculator/GapFundingCalculator";
import ParentFilter from "./ParentFilter/ParentFilter";
import GeographyComparator from "./GeographyComparator/GeographyComparator";
import { ErrorBoundary } from "./ErrorBoundary";
import type { WebReportTheme } from "@api/projects/projects.types";

type WidgetContainerProps = {
  widget: WidgetData;
  report: ReportData;
  filterState: FilterState;
  setCharts: Dispatch<SetStateAction<ChartData[]>>;
  customGeoIds?: string[];
  theme: WebReportTheme;
};

const widgetComponents: {
  [key: string]: React.FunctionComponent<WidgetProps>;
} = {
  gap_funding_calculator: GapFundingCalculator,
  parent_filter: ParentFilter,
  geography_comparator: GeographyComparator,
};

export function WidgetContainer(widgetProps: WidgetContainerProps) {
  const { widget, report, filterState, setCharts, customGeoIds, theme } = widgetProps;
  const WidgetComponent = widgetComponents[widget.component];
  return (
    <ErrorBoundary>
      <MantineProvider
        theme={{
          primaryColor: "theme",
          colors: {
            theme: [
              theme.colors.main,
              theme.colors.main,
              theme.colors.main,
              theme.colors.main,
              theme.colors.main,
              theme.colors.main,
              theme.colors.main,
              theme.colors.main,
            ],
          },
        }}
      >
        <WidgetWrapper>
          <WidgetComponent
            widget={widget}
            theme={theme}
            geography={report.geography}
            isDraft={report.isDraft}
            report={report}
            filterState={filterState}
            setCharts={setCharts}
            customGeoIds={customGeoIds}
          />
        </WidgetWrapper>
      </MantineProvider>
    </ErrorBoundary>
  );
}
