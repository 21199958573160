import type { ChapterData, SectionData } from "@api/reports/output/output.types";
import { ReportLayout } from "./types";

export function sectionHasContent(layout: ReportLayout) {
  return function sectionHasContent(section: SectionData) {
    const output = layout === "screen" ? "web" : "pdf";
    return (
      section.summary.text !== "" ||
      !!section.charts.find(
        // @ts-ignore
        (chart) => !chart.output || chart.output.includes("all") || chart.output.includes(output)
      ) ||
      section.widgets.length > 0
    );
  };
}

export function chapterHasContent(layout: ReportLayout) {
  return function chapterHasContent(chapter: ChapterData) {
    return !!chapter.sections.find(sectionHasContent(layout));
  };
}

export function parseStringToArray(input?: string): string[] {
  if (!input) {
    return [];
  }
  const obj = parseStringToObject(input);
  return Object.keys(obj).map((key) => `${key.trim()}="${obj[key].trim()}"`);
}

function parseStringToObject(input: string): Record<string, string> {
  const keyValuePairs = input.match(/(\w+\s*=\s*"[^"]+"|[^,]+)/g);
  const result: Record<string, string> = {};

  if (keyValuePairs) {
    for (const pair of keyValuePairs) {
      const [key, value] = pair.split("=").map((item) => item.trim());

      if (value.startsWith('"') && value.endsWith('"')) {
        // Handle quoted values
        result[key] = value.slice(1, -1);
      } else {
        // Handle unquoted values
        result[key] = value;
      }
    }
  }
  return result;
}
