import styled from "@emotion/styled";
import { Group } from "@mantine/core";

export const MainHeaderWrapper = styled.header<{ color: string; withNav: boolean }>`
  z-index: 3;
  position: fixed;
  top: 0;
  width: 100%;
  height: var(--header-height);
  background: ${(props) => props.color};
  box-shadow: ${(props) => (props.withNav ? "" : "0px 4px 4px 0px rgb(0 0 0 / 20%)")};
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    position: static;
  }
`;

export const MainHeader = styled.div`
  width: 100%;
  max-width: var(--main-container-width);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export const HeaderLogo = styled.div`
  width: var(--nav-width);
  min-width: var(--nav-width);
  padding-left: var(--content-padding);
  text-align: center;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }

  img {
    width: 100%;
    height: 3.8rem;
    object-fit: contain;
    object-position: center;
  }
`;

export const DrawerLogo = styled.div`
  img {
    max-width: 100%;
    height: 3rem;
    object-fit: contain;
    object-position: center;
  }
`;

export const HeaderContent = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  overflow: hidden;
  padding-left: var(--nav-margin);

  h1 {
    color: white;
    font-size: 2.8em;
    display: block;
    font-weight: 400;
    text-transform: capitalize;
    letter-spacing: -1px;
    white-space: nowrap;
    margin-right: 1em;
  }

  h2 {
    font-weight: 400;
    color: white;
    font-size: 1.45rem;
    padding-left: 0.2rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    h1 {
      font-size: 2.1rem;
    }

    h2 {
      font-size: 1.2rem;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-left: var(--content-padding);
  }
`;

export const Downloading = styled.div`
  color: white;
  font-size: 12px;
  position: absolute;
  top: 35px;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    color: ${({ theme }): string => theme.colors["report"]?.[0]};
    font-size: 1.1rem;
    top: 65px;
  }
`;

export const HeaderNav = styled.nav`
  background: #f0f4f9;
  position: fixed;
  width: 100%;
  top: 96px;
  padding: 2px;
  box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 10%);
  z-index: 3;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0;
  }

  a {
    color: ${({ theme }): string => theme.fn.lighten(theme.colors["report"]?.[0], 0.1)};
    font-size: 1rem;

    &:not(.active):hover {
      background-color: #ffffff;
    }

    &.active {
      background-color: ${({ theme }): string => theme.colors["report"]?.[0]};
      color: #ffffff;
    }
  }
`;

export const HeaderGroup = styled(Group)`
  width: 100%;
  max-width: var(--main-container-width);
  margin: auto;
  padding: 2px var(--content-padding);
`;
