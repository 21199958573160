"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StackedHorizontalBarChartGroup = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _templateObject;
const StackedHorizontalBarChartGroup = _styled.default.g(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  text {\n    color: #fff;\n    font-family: inherit;\n    font-weight: 600;\n    font-size: clamp(0.8rem, 1vw, 1rem);\n  }\n\n  .small-label & {\n    text {\n      font-size: clamp(0.7rem, 0.9vw, 0.9rem);\n    }\n  }\n\n  .semitransparent {\n    opacity: 0.2;\n  }\n"])));
exports.StackedHorizontalBarChartGroup = StackedHorizontalBarChartGroup;