"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useColor = useColor;
var _colorInterpolate = _interopRequireDefault(require("color-interpolate"));
var _colorString = _interopRequireDefault(require("color-string"));
var _colorConvert = _interopRequireDefault(require("color-convert"));
var _react = require("react");
function useColor(colorConfig, colorScheme) {
  const {
    direction,
    divergingMidPoint,
    steps
  } = colorConfig.settings || {
    direction: "ascending",
    divergingMidPoint: 0,
    steps: 6
  };
  const colorPalette = (0, _react.useMemo)(() => {
    const palette = colorScheme !== "none" ? [...colorConfig.schemes[colorScheme]] : [];
    if (direction === "descending") {
      palette.reverse();
    }
    return palette;
  }, [colorConfig.schemes, colorScheme]);
  const calculateColorStops = function (values) {
    let sorted = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    if (values.length === 0 || colorPalette.length === 0) {
      return [];
    }
    if (!sorted) {
      values = [...values].sort((a, b) => a - b);
    }
    if (colorScheme === "categorical") {
      return values.map((value, idx) => [value, colorPalette[idx]]);
    } else if (colorScheme === "sequential") {
      const colormap = (0, _colorInterpolate.default)(colorPalette);
      const percentiles = getPercentiles(values, steps);
      return getStops(percentiles, colormap);
    } else if (colorScheme === "diverging") {
      const stops = [];
      const negValues = values.filter(v => v < divergingMidPoint);
      if (negValues.length > 0) {
        const negPercentiles = getPercentiles(negValues, steps);
        negPercentiles.push(divergingMidPoint);
        stops.push(...getStops(negPercentiles, (0, _colorInterpolate.default)([colorPalette[0], colorPalette[1]])));
      }
      const posValues = values.filter(v => v >= divergingMidPoint);
      if (posValues.length > 0) {
        const positive = getPercentiles(posValues, steps);
        positive.unshift(divergingMidPoint);
        stops.push(...getStops(positive, (0, _colorInterpolate.default)([colorPalette[1], colorPalette[2]])).slice(1));
      }
      return stops;
    }
  };
  const getContrastColor = function (hexColor) {
    let light = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "#FFFFFF";
    let dark = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "#333333";
    const [h, s, l] = _colorConvert.default.hex.hsl(hexColor);
    return l > 65 ? dark : light;
  };
  return {
    calculateColorStops,
    getContrastColor,
    colorPalette
  };
}
function getPercentiles(sortedValues, percentil) {
  const result = [];
  percentil++;
  sortedValues = Array.from(new Set(sortedValues)).sort((a, b) => a - b);
  const step = (sortedValues.length - 1) / percentil;
  for (let i = 1; i < percentil; i++) {
    const index = Math.floor(step * i);
    result.push(sortedValues[index]);
  }
  return result;
}
function getStops(percentiles, colormap) {
  const stops = [];
  for (const percentile of percentiles) {
    let normalized = (percentile - percentiles.at(0)) / (percentiles.at(-1) - percentiles.at(0));
    if (isNaN(normalized)) {
      normalized = 0;
    }
    try {
      const color = colormap(normalized);
      stops.push([percentile, _colorString.default.to.hex(_colorString.default.get.rgb(color))]);
    } catch (e) {
      console.log(e);
    }
  }
  stops.sort((a, b) => Number(a[0]) - Number(b[0]));
  return stops;
}