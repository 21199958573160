import React, { useEffect } from "react";
import { useApi } from "../hooks/useApi";
import type { ProjectWithReportsOut } from "@api/projects/projects.types";
import { LoadingScreen } from "./Report/ScreenReport.styled";
import { GridLoader } from "react-spinners";
import ScreenReport from "./Report/ScreenReport";
import { useParams } from "react-router-dom";
import Portal from "./Report/Portal";

export default function FrontController() {
  const { pageId } = useParams();

  const {
    data: projects,
    loading: searchingProjects,
    callApi: searchProjects,
  } = useApi<ProjectWithReportsOut[], {}, { by: string; q: string }>("/projects/search");

  useEffect(() => {
    searchProjects({ query: { q: window.location.hostname, by: "domain" } });
  }, [searchProjects]);

  const project = projects?.[0];
  const report = project?.reports?.[0];
  const pages = report?.pages.sort((a, b) => a.order - b.order);

  return (
    <>
      {project?.app.configuration.landingPage.behavior === "single_report" && (
        <ScreenReport
          reportId={report._id}
          geoId={project.app.configuration.landingPage.configuration.geoId}
          custom={[]}
          exclude={[]}
          filters={[]}
          pageId={pageId || pages[0]._id}
          baseUrl={"/page"}
        />
      )}
      {project?.app.configuration.landingPage.behavior === "portal" && <Portal project={project} />}

      {!report && searchingProjects && (
        <LoadingScreen>
          <GridLoader size={30} color={"#ccc"} />
        </LoadingScreen>
      )}
    </>
  );
}
