import React, { useRef } from "react";
import { ChartTitle, ChartWrapper, HorizontalLegend, ChartCategoricalFormat } from "@hra/hna-charts";
import * as d3 from "d3";
import type { ThemeData } from "@api/reports/output/output.types";
import { formatNumber } from "./util";
import type { WebReportTheme } from "@api/projects/projects.types";

type ResultPieChartProps = {
  colorScheme: ThemeData;
  theme: WebReportTheme;
  unitsByDevType: {
    new4: number;
    new9: number;
    rehab4: number;
    rehab9: number;
    pfc: number;
  };
};

const MARGIN = { top: 40, right: 20, bottom: 30, left: 20 };
const DEV_TYPES = {
  new4: "New 4%",
  new9: "New 9%",
  rehab4: "Acq/Rehab (4%)",
  rehab9: "Acq/Rehab (9%)",
  pfc: "PFC",
};

export default function ResultPieChart({ colorScheme, theme, unitsByDevType }: ResultPieChartProps) {
  const svgRef = useRef<SVGSVGElement>(null);
  const width = 380;
  const height = 300;

  const boundsWidth = width - MARGIN.right - MARGIN.left;
  const boundsHeight = height - MARGIN.top - MARGIN.bottom;
  const radius = Math.min(width, height) / 2;

  const pieGenerator = d3.pie<{ value: number; label: string; key: string }>().value((d) => Number(d.value));
  const arcGenerator = d3
    .arc()
    .innerRadius(radius * 0.35)
    .outerRadius(radius);

  const points = Object.keys(unitsByDevType).map((devType) => ({
    value: unitsByDevType[devType as keyof typeof unitsByDevType],
    label: DEV_TYPES[devType as keyof typeof DEV_TYPES],
    key: devType,
  }));

  const colorPalette = d3
    .scaleOrdinal<string>()
    .domain(points.map((p) => p.key))
    .range(colorScheme.colors.schemes.categorical);

  const arcData = pieGenerator(points);

  return (
    <ChartWrapper spanRow={false}>
      <ChartTitle color={theme.colors.main} layout="screen">
        Units Built by Development Type
      </ChartTitle>
      <svg
        ref={svgRef}
        width={width}
        height={height}
        style={{
          background: "white",
          borderRadius: "8px",
          display: "block",
          margin: "auto",
          marginTop: "1.5rem",
          marginBottom: "1.5rem",
        }}
        viewBox={`0 0 ${width} ${height}`}
      >
        <g width={boundsWidth} height={boundsHeight} transform={`translate(${[width / 2, height / 2].join(",")})`}>
          {arcData.map((d) => (
            <g key={d.index}>
              <path
                className={`arc`}
                d={arcGenerator(d as any)}
                fill={colorPalette(d.data.key)}
                stroke="white"
                strokeWidth={3}
              ></path>
              <text
                className="inside-label"
                transform={`translate(${arcGenerator.centroid(d as any)})`}
                textAnchor="middle"
                fontWeight={"bold"}
                fill={"white"}
              >
                {d.endAngle - d.startAngle > 0.35 ? formatNumber(d.data.value) : ""}
              </text>
            </g>
          ))}
        </g>
      </svg>
      <HorizontalLegend
        labels={points}
        palette={colorScheme.colors.schemes.categorical}
        format={ChartCategoricalFormat.LABEL}
      />
    </ChartWrapper>
  );
}
