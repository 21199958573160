import React from "react";

export class ErrorBoundary extends React.Component<{ children: any }, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      return <div>Sorry, something went wrong with this chart.</div>;
    }

    return this.props.children;
  }
}
