"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useElementMargin = useElementMargin;
var _react = require("react");
function useElementMargin(svgRef, setMargin, selector) {
  let dependencies = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  (0, _react.useLayoutEffect)(() => {
    if (!svgRef.current || !selector) {
      return;
    }
    let bottomOffset = 0,
      leftOffset = 0,
      rightOffset = 0,
      topOffset = 0;
    setTimeout(() => {
      const elements = svgRef.current.querySelectorAll(selector);
      for (const element of elements) {
        const offsets = getElementSVGOffsets(svgRef.current, element);
        if (offsets.bottom < 0) {
          bottomOffset = Math.max(Math.abs(offsets.bottom), bottomOffset);
        }
        if (offsets.left < 0) {
          leftOffset = Math.max(Math.abs(offsets.left) + 4, leftOffset);
        }
        if (offsets.right < 0) {
          rightOffset = Math.max(Math.abs(offsets.right) + 4, rightOffset);
        }
        if (offsets.top < 0) {
          topOffset = Math.max(Math.abs(offsets.top), topOffset);
        }
      }
      if (bottomOffset > 0 || leftOffset > 0 || rightOffset > 0 || topOffset > 0) {
        setMargin(margin => ({
          top: margin.top + topOffset,
          bottom: margin.bottom + bottomOffset,
          left: margin.left + leftOffset,
          right: margin.right + rightOffset
        }));
      }
    }, 0);
    // Undo side effect
    return () => {
      setTimeout(() => {
        setMargin(margin => ({
          top: margin.top - topOffset,
          bottom: margin.bottom - bottomOffset,
          left: margin.left - leftOffset,
          right: margin.right - rightOffset
        }));
      }, 0);
    };
  }, [selector, svgRef.current, ...dependencies]);
}
function getElementSVGOffsets(svg, element) {
  const result = {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
  };
  // Get the bounding box of the SVG and the text element
  const svgBBox = svg.getBoundingClientRect();
  const textBBox = element.getBoundingClientRect();
  result.left = textBBox.x - svgBBox.x;
  result.top = textBBox.y - svgBBox.y;
  result.right = svgBBox.x + svgBBox.width - (textBBox.x + textBBox.width);
  result.bottom = svgBBox.y + svgBBox.height - (textBBox.y + textBBox.height);
  return result;
}