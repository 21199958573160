import axios, { AxiosError } from "axios";
import { useCallback, useRef, useState } from "react";
import { compile } from "path-to-regexp";

type ApiCallParams<D, P, Q> = {
  data?: D;
  params?: P;
  query?: D | Q;
  headers?: Record<string, string>;
};

export type ApiError = {
  message: string;
  data?: Record<string, string>;
  code?: string;
};

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export function useApi<R, P extends object = Record<string, string>, D = unknown, Q = D>(
  path: string,
  method = "get",
  resolveWithData = false
) {
  const [data, setData] = useState<R | null>(null);
  const [error, setError] = useState<ApiError | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const controller = useRef(new AbortController());

  const callApi = useCallback(
    ({ data, query, params, headers }: ApiCallParams<D, P, Q> = {}): Promise<boolean | R | AxiosError> => {
      setLoading(true);
      return new Promise((resolve) => {
        const url = params ? parseUrl<P>(path, params) : path;
        let success = false;
        axiosInstance
          .request<R>({
            url,
            method,
            params: query,
            data: method !== "get" ? data : undefined,
            signal: controller.current.signal,
            headers: { ...headers, "ngrok-skip-browser-warning": "3141" },
          })
          .then((response) => {
            setData(response.data);
            setError(null);
            if (resolveWithData) {
              resolve(response.data);
            }
            success = true;
          })
          .catch((error) => {
            if (error instanceof AxiosError) {
              console.log(error);
              if (error.response) {
                setError({
                  message: `Error ${error.code?.toString() || ""}`,
                  data: error.response.data,
                  code: error.code,
                });
              } else if (error.request) {
                setError({ message: "No response received from server", code: error.code });
              }
              if (resolveWithData) {
                resolve(error);
              }
            } else if (error instanceof Error) {
              setError({ message: error.message });
            } else {
              setError({ message: "Unknown error" });
            }
          })
          .finally(() => {
            setLoading(false);
            if (!resolveWithData || !success) {
              resolve(success);
            }
          });
      });
    },
    [method, path, resolveWithData]
  );

  const abortCall = useCallback(() => {
    controller.current.abort();
  }, []);

  return { data, error, loading, callApi, abortCall, setData };
}

function parseUrl<P extends object>(path: string, params: P) {
  const toPath = compile<P>(path, { encode: encodeURIComponent });
  return toPath(params);
}
