import React from "react";
import { Route, Routes } from "react-router-dom";
import Report from "./pages/Report/Report";
import FrontController from "./pages/FrontController";

function App() {
  return (
    <Routes>
      {/* Legacy Paths */}
      <Route path="/:reportId/:geoId" element={<Report layout="screen" />}></Route>
      <Route path="/:reportId/report/:geoId" element={<Report layout="screen" />}></Route>
      {/* Current Paths */}
      <Route path="/" element={<FrontController />}></Route>
      <Route path="/page/:pageId" element={<FrontController />}></Route>
      <Route path="/report/:reportId/:geoId" element={<Report layout="screen" />}></Route>
      <Route path="/report/:reportId/:geoId/:pageId" element={<Report layout="screen" />}></Route>
      <Route path="/report/pdf/:reportId/:geoId" element={<Report layout="print" />}></Route>
    </Routes>
  );
}

export default App;
