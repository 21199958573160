import styled from "@emotion/styled";

export const GapFundingCalculatorWrapper = styled.form<{ color: string }>`
  .instructions {
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-bottom: 0.7rem;
      color: ${(props) => props.color};
      font-weight: 500;
    }
    h1 {
      font-size: 1.6rem;
    }
    h2 {
      font-size: 1.5rem;
    }
    h3 {
      font-size: 1.3rem;
    }
    h4,
    h5,
    h6 {
      font-size: 1rem;
    }
    p,
    li {
      font-weight: 300;
      font-size: 1.05rem;
      line-height: 1.55rem;
      color: #111;
      margin-bottom: 0.8rem;
      list-style-type: circle;
    }

    li {
      margin-left: 16px;
    }

    strong {
      color: ${(props) => props.color};
      font-weight: 600;
    }
    em {
      font-weight: 400;
      font-style: normal;
    }
    a {
      color: ${(props) => props.color};
    }
  }

  position: relative;
`;
export const ShareInputTitle = styled.h3<{ color: string }>`
  font-size: 1.1rem;
  margin: 0;
  margin-bottom: 12px;
  padding: 0;
  color: ${(props) => props.color};
  font-weight: 600;
`;

export const ShareInputWrapper = styled.div`
  margin-bottom: 0.8rem;
  background: #f0f4f9;
  border-radius: 0.5em;
  padding: 0.8rem;
  min-height: 162px;
`;

export const GoalItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const GoalLabel = styled.div<{ selected: boolean }>`
  width: 110px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  color: #333;
`;

export const GoalInput = styled.div`
  max-width: calc(100% - 160px);
`;

export const ShareValue = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
`;

export const SelectedValue = styled.div`
  font-weight: bold;
`;

export const MainResult = styled.div`
  background: #f0f4f9;
  padding: 1rem 0.8rem;
  font-size: 1.1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  font-weight: 500;
`;

export const ResultList = styled.ul`
  padding-left: 0.5rem;
`;
