"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChartOps = void 0;
exports.boxesIntersect = boxesIntersect;
exports.cutLabel = cutLabel;
exports.formatDate = formatDate;
exports.getColumnLabels = getColumnLabels;
exports.ratioToNumber = ratioToNumber;
exports.stringToTimestamp = stringToTimestamp;
exports.timestampToString = timestampToString;
var _lodash = _interopRequireDefault(require("lodash.uniqby"));
var _constants = require("../constants");
var _lodash2 = require("lodash");
class ChartOps {
  static getSeriesLabels(chart) {
    var _columns$seriesCol;
    let keepAggregate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    const {
      dataView,
      seriesCol
    } = chart;
    const {
      data,
      columns
    } = dataView;
    const categories = (_columns$seriesCol = columns[seriesCol]) === null || _columns$seriesCol === void 0 ? void 0 : _columns$seriesCol.categories;
    if (!categories) {
      console.warn("Column ".concat(seriesCol, " not present in columns."));
      return data.map(d => d.seriesKey).map(key => ({
        key,
        label: key
      }));
    }
    let seriesKeys = data.map(d => d.seriesKey);
    seriesKeys = seriesKeys.filter(key => categories[key] ? categories[key].visible : true);
    seriesKeys = seriesKeys.filter(key => {
      var _categories$key;
      return keepAggregate || !((_categories$key = categories[key]) !== null && _categories$key !== void 0 && _categories$key.aggregate);
    });
    seriesKeys = (0, _lodash2.sortBy)(seriesKeys, key => {
      var _categories$key2;
      return chart.seriesCol !== _constants.COL_DATE ? (_categories$key2 = categories[key]) === null || _categories$key2 === void 0 ? void 0 : _categories$key2.position : stringToTimestamp(key);
    });
    return seriesKeys.map(key => {
      var _categories$key3;
      return {
        key,
        label: chart.seriesCol !== _constants.COL_DATE ? ((_categories$key3 = categories[key]) === null || _categories$key3 === void 0 ? void 0 : _categories$key3.label) || key : formatDate(key)
      };
    });
  }
}
exports.ChartOps = ChartOps;
function getColumnLabels(data, col, columns) {
  let removeNonYears = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  // If it's not a categorical variable, return null;

  if (!columns[col] && ![_constants.COL_GEO_ID, _constants.COL_DATE].includes(col)) {
    var _data$, _data$$points$;
    // Non-numeric
    if (isNaN(Number((_data$ = data[0]) === null || _data$ === void 0 ? void 0 : (_data$$points$ = _data$.points[0]) === null || _data$$points$ === void 0 ? void 0 : _data$$points$[col]))) {
      return Array.from(new Set(data.map(d => d.points.map(p => p[col])).flat())).sort().map(key => ({
        key,
        label: key
      }));
    }
    return null;
  }
  if (col === _constants.COL_DATE) {
    const labels = data.map(d => d.points.map(p => ({
      key: p[col],
      label: formatDate(p[col])
    }))).flat();
    return (0, _lodash2.sortBy)(labels, label => stringToTimestamp(label.key));
  }
  const categories = columns[col].categories;
  let labels = data.map(d => d.points.map(p => {
    var _categories$p$col;
    return {
      key: p[col],
      label: ((_categories$p$col = categories[p[col]]) === null || _categories$p$col === void 0 ? void 0 : _categories$p$col.label) || p[col]
    };
  })).flat().filter(val => categories[val.key] ? categories[val.key].visible : true).filter(val => !removeNonYears || col === _constants.COL_YEAR && !isNaN(Number(val.key)));
  labels = (0, _lodash.default)(labels, label => label.key);
  labels = (0, _lodash2.sortBy)(labels, (label, idx) => {
    var _categories$label$key;
    return ((_categories$label$key = categories[label.key]) === null || _categories$label$key === void 0 ? void 0 : _categories$label$key.position) || idx;
  });
  return labels;
}
function formatDate(strDate) {
  const date = new Date(strDate);
  const formatter = Intl.DateTimeFormat("en-US", {
    month: "short",
    year: "numeric",
    timeZone: "UTC"
  });
  return formatter.format(date);
}
function boxesIntersect(box1, box2) {
  return !(box2.x > box1.x + box1.width || box2.x + box2.width < box1.x || box2.y > box1.y + box1.height || box2.y + box2.height < box1.y);
}
function stringToTimestamp(dateStr) {
  const date = new Date(dateStr);
  return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDay());
}
;
function timestampToString(timestamp) {
  const dateObj = new Date(timestamp);
  const year = dateObj.getUTCFullYear();
  const month = dateObj.getUTCMonth() + 1; // Months are zero-based, so we add 1 to get the correct month.
  // Pad the month with a leading zero if needed (e.g., "04" instead of "4").
  const formattedMonth = month.toString().padStart(2, '0');
  return "".concat(year, "-").concat(formattedMonth);
}
function ratioToNumber(ratio) {
  if (!ratio) {
    return 5 / 3;
  }
  const [width, height] = ratio.split(":");
  return Number(width) / Number(height);
}
function cutLabel(label) {
  let maxChars = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 24;
  return label.slice(0, maxChars) + (label.length > maxChars ? "…" : "");
}