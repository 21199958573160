"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VerticalLegendItems = exports.RangeBand = exports.MinValue = exports.MaxValue = exports.LegendItem = exports.HorizontalRangeLegendGradient = exports.HorizontalLegendItems = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
const HorizontalLegendItems = _styled.default.ul(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  width: 84%;\n  column-gap: 16px;\n  row-gap: 6px;\n  margin: 6px auto;\n  justify-content: center;\n  flex-wrap: wrap;\n  max-width: 600px;\n"])));
exports.HorizontalLegendItems = HorizontalLegendItems;
const LegendItem = _styled.default.li(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  align-items: top;\n  gap: 6px;\n  > div:first-of-type {\n    width: 15px;\n    height: 15px;\n    margin-top: 5px;\n    flex-shrink: 0;\n    border-radius: 50%;\n  }\n"])));
exports.LegendItem = LegendItem;
const HorizontalRangeLegendGradient = _styled.default.div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin: 20px auto;\n  gap: 12px;\n"])));
exports.HorizontalRangeLegendGradient = HorizontalRangeLegendGradient;
const RangeBand = _styled.default.div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  height: 15px;\n  width: 100px;\n  border-radius: 8px;\n"])));
exports.RangeBand = RangeBand;
const MinValue = _styled.default.div(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)([""])));
exports.MinValue = MinValue;
const MaxValue = _styled.default.div(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)([""])));
exports.MaxValue = MaxValue;
const VerticalLegendItems = _styled.default.div(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\n  margin: 0;\n  position: absolute;\n  right: 0;\n  width: 130px;\n  height: 90%;\n  overflow: auto;\n  top: 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: left;\n"])));
exports.VerticalLegendItems = VerticalLegendItems;