"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useChartDimensions = useChartDimensions;
var _hooks = require("@mantine/hooks");
var _react = require("react");
var _useElementMargin = require("./useElementMargin");
function useChartDimensions(svgRef, baseMargin, spanRow, selector) {
  let dependencies = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];
  const [width, setWidth] = (0, _react.useState)(null);
  const [height, setHeight] = (0, _react.useState)(null);
  const [margin, setMargin] = (0, _react.useState)({
    ...baseMargin
  });
  const [winWidth, setWinWidth] = (0, _hooks.useDebouncedState)(window.innerWidth, 200);
  (0, _react.useLayoutEffect)(() => {
    const update = () => setWinWidth(window.innerWidth);
    window.addEventListener("resize", update);
    return () => window.removeEventListener("resize", update);
  }, []);
  (0, _react.useLayoutEffect)(() => {
    if (!svgRef.current) {
      return;
    }
    const innerWidth = svgRef.current.width.baseVal.value - margin.left - margin.right;
    const innerHeight = svgRef.current.height.baseVal.value - margin.top - margin.bottom;
    setWidth(innerWidth);
    setHeight(innerHeight);
  }, [margin, winWidth, spanRow, svgRef.current]);
  (0, _useElementMargin.useElementMargin)(svgRef, setMargin, selector, dependencies);
  return {
    width,
    height,
    margin
  };
}