import styled from "@emotion/styled";

export const ChapterWrapper = styled.section``;

export const TitleBar = styled.div`
  background: rgb(42, 109, 173);
  background: linear-gradient(90deg, white 0%, ${(props) => props.color} 55%);
  color: #fff;
  padding: 1em;
  border-radius: 0.2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.5em;

  h1 {
    font-size: 2.1rem;
    font-weight: normal;
  }
`;

export const ChapterTitle = styled.div<{ color: string }>`
  background: ${(props) => props.color};
  color: #fff;
  height: 816px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  page-break-inside: avoid;
  page-break-before: always;
  page-break-after: always;
  counter-increment: page;
  position: relative;
  gap: 2.2rem;

  h1 {
    font-size: 3.5em;
    font-weight: 500;
  }

  h2 {
    font-weight: normal;
    font-size: 2.3em;
    text-align: center;
  }

  h3 {
    font-weight: normal;
    font-size: 1.2em;
    position: absolute;
    bottom: 20px;
    text-align: center;
    margin: auto;
    padding-top: 4px;
    width: 100%;
  }
`;

export const ChapterLogo = styled.div`
  position: absolute;
  top: 24px;
  left: 36px;
  width: 18.5em;
  min-width: 18.5em;

  img {
    height: 3.2em;
    object-fit: contain;
  }
`;
