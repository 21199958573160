import styled from "@emotion/styled";

export const ParentFilterPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #f0f4f9;
  padding: 0.5rem;
  border-radius: 0.6rem;
`;
