import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { WidgetProps } from "../types";
import type { WidgetData } from "@api/reports/output/output.types";
import type { CalculationResults } from "@api/scripts/gap-funding-calculator/gap-funding-calcultator.types";
import {
  GapFundingCalculatorWrapper,
  MainResult,
  ResultList,
  ShareInputTitle,
  ShareInputWrapper,
} from "./GapFundingCalculator.styled";
import { Grid, NumberInput, Group, Button, SelectItem, Container, Radio, createStyles, Stack } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ShareInput } from "./ShareInput";
import ResultShareChart from "./ResultShareChart";
import { formatMoney, formatNumber } from "./util";
import ResultPieChart from "./ResultPieChart";
import ResultBarChart from "./ResultBarChart";
import { useApi } from "../../../../hooks/useApi";
import { usePrevious, useScrollIntoView } from "@mantine/hooks";

export type GapFundingCalculatorProps = Omit<WidgetProps, "widget"> & {
  widget: Omit<WidgetData, "componentProps"> & {
    componentProps: {
      scriptId: string;
      assumptions: string;
      goals: string;
      needs: {
        current: number;
        projected: number;
      };
    };
  };
};

export const useFormStyles = createStyles((theme) => ({
  label: {
    display: "inline-block",
  },
  labelWrapper: {
    fontFamily: "Work Sans",
    fontSize: "1rem",
    fontWeight: 300,
  },
}));

const SCRIPT_ID = "gap_funding_calculator";
const LABELS = {
  amiShare: {
    ami30: "30% of AMI",
    ami50: "50% of AMI",
    ami60: "60% of AMI",
    ami80: "80% of AMI",
    marketRate: "Market Rate",
  },
  unit: {
    studio: "1-Person HH",
    bed1: "2-Person HH",
    bed2: "3-Person HH",
    bed3: "4-Person HH",
    bed4: "5+ Person HH",
  },
};

export default function GapFundingCalculator({ widget, theme, report }: GapFundingCalculatorProps) {
  const { classes } = useFormStyles();
  const [selectedInput, setSelectedInput] = useState<"build" | "spend">("build");
  const { scrollIntoView: scrollToResults, targetRef: resultsRef } = useScrollIntoView<HTMLDivElement>({
    offset: 180,
    duration: 300,
  });
  const { scrollIntoView: scrollToInputs, targetRef: inputsRef } = useScrollIntoView<HTMLDivElement>({
    offset: 220,
    duration: 300,
  });

  const {
    data: results,
    setData: setResults,
    error: scriptError, // TODO: Do something
    callApi: runScript,
    loading: sending,
  } = useApi<CalculationResults>(`/scripts/${SCRIPT_ID}/run`, "post");

  const previousValue = usePrevious(results);

  const form = useForm<any>({
    initialValues: {
      amiShare: {
        ami30: 0,
        ami50: 0,
        ami60: 0,
        ami80: 0,
        marketRate: 0,
      },
      unitMix: {
        studio: 0,
        bed1: 0,
        bed2: 0,
        bed3: 0,
        bed4: 0,
      },
      landValue: "",
      targets: {
        unitGoal: 0,
        totalMoneyInvested: 0,
      },
    },
    validate: {
      amiShare: (value) =>
        Object.values(value).reduce((sum: number, value: number) => value + sum, 0) !== 100
          ? "The shares must add up to exactly 100%"
          : null,
      unitMix: (value) =>
        Object.values(value).reduce((sum: number, value: number) => value + sum, 0) !== 100
          ? "The units must add up to exactly 100%"
          : null,
      targets: {
        unitGoal: (value) =>
          isNaN(Number(value)) || value?.toString().trim() === "" ? "Unit goal cannot be empty" : null,
        totalMoneyInvested: (value) =>
          isNaN(Number(value)) || value?.toString().trim() === "" ? "Money invested cannot be empty" : null,
      },
    },
  });

  const { scriptId, goals, assumptions, needs } = widget.componentProps;
  const inputs = report.project.scripts.find((script) => script._id.toString() === scriptId)?.defaultInput;

  useEffect(() => {
    if (inputs && form.values.landValue === "") {
      for (const field of Object.keys(inputs)) {
        form.setFieldValue(field, inputs[field]);
      }
    }
  }, [inputs, form]);

  useEffect(() => {
    if (results) {
      scrollToResults();
    } else if (previousValue) {
      scrollToInputs();
    }
  }, [results, previousValue, scrollToResults, scrollToInputs]);

  async function handleSubmit(values: any) {
    if (!form.validate()) {
      return;
    }
    void runScript({ data: values });
  }

  const landValueItems: SelectItem[] = [
    { value: "currentArea", label: "I want to build in the areas where the units are currently being built." },
    { value: "highestArea50", label: "I want to build 50% of the units in the highest opportunity areas." },
    { value: "highestArea100", label: "I want to build all units in the highest opportunity areas." },
  ];

  return (
    <GapFundingCalculatorWrapper color={theme.colors.main} onSubmit={form.onSubmit(handleSubmit)}>
      <Container fluid p={0} ref={inputsRef}>
        <Grid m={0}>
          <Grid.Col xl={12} sm={12} py={0} pr={12} pl={0}>
            <ReactMarkdown className="instructions">{goals}</ReactMarkdown>
          </Grid.Col>
          <Grid.Col xl={12} sm={12} p={0} mt={10}>
            <Grid>
              <Grid.Col xl={6} sm={12} py={0}>
                <ShareInputWrapper>
                  <ShareInputTitle color={theme.colors.main}>What is your goal?</ShareInputTitle>
                  {!results && (
                    <Radio.Group
                      value={selectedInput}
                      onChange={(value) => setSelectedInput(value as "build" | "spend")}
                    >
                      <Stack>
                        <Radio
                          classNames={classes}
                          styles={{ inner: { top: "8px" } }}
                          value="build"
                          label={
                            <Group>
                              I want to build
                              <NumberInput
                                {...form.getInputProps("targets.unitGoal")}
                                styles={{
                                  root: {
                                    width: "120px",
                                    maxWidth: "100%",
                                  },
                                  input: {
                                    fontSize: "16px",
                                  },
                                }}
                                min={0}
                                step={100}
                                autoComplete="off"
                                parser={(value) => value?.replace(/\s|,/g, "")}
                                formatter={(value) => formatNumber(Number(value?.replace(/\s|,/g, "")))}
                                onFocus={() => setSelectedInput("build")}
                              />
                              units
                            </Group>
                          }
                        />
                        <Radio
                          classNames={classes}
                          styles={{ inner: { top: "8px" } }}
                          value="spend"
                          label={
                            <Group>
                              I want to spend
                              <NumberInput
                                {...form.getInputProps("targets.totalMoneyInvested")}
                                styles={{
                                  root: {
                                    width: "180px",
                                    maxWidth: "100%",
                                  },
                                  input: {
                                    fontSize: "16px",
                                  },
                                }}
                                min={0}
                                step={1_000_000}
                                autoComplete="off"
                                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                                formatter={(value) => formatMoney(Number(value?.replace(/\$\s?|(,*)/g, "")))}
                                onFocus={() => setSelectedInput("spend")}
                              />
                            </Group>
                          }
                        />
                      </Stack>
                    </Radio.Group>
                  )}
                  {results && (
                    <span>
                      {selectedInput === "build" && (
                        <>
                          I want to build <strong>{form.values.targets.unitGoal}</strong> units
                        </>
                      )}
                      {selectedInput === "spend" && (
                        <>
                          I want to spend <strong>{formatMoney(form.values.targets.totalMoneyInvested)}</strong>
                        </>
                      )}
                    </span>
                  )}
                </ShareInputWrapper>
              </Grid.Col>
              <Grid.Col xl={6} sm={12} py={0}>
                <ShareInputWrapper>
                  <ShareInputTitle color={theme.colors.main}>Where do you want to build?</ShareInputTitle>
                  {!results && (
                    <Radio.Group {...form.getInputProps("landValue")}>
                      <Group dir="column" spacing={"sm"} sx={{ marginTop: "4px" }}>
                        {landValueItems.map((item) => (
                          <Radio classNames={classes} value={item.value} label={item.label} key={item.value} />
                        ))}
                      </Group>
                    </Radio.Group>
                  )}
                  {results && (
                    <strong>{landValueItems.find((item) => item.value === form.values.landValue).label}</strong>
                  )}
                </ShareInputWrapper>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </Container>
      <Container fluid p={0} mt={16}>
        <Grid m={0}>
          <Grid.Col xl={12} sm={12} py={0} pr={12} pl={0}>
            <ReactMarkdown className="instructions">{assumptions}</ReactMarkdown>
          </Grid.Col>
          <Grid.Col xl={12} sm={12} p={0}>
            <ShareInput
              form={form}
              title={"Share of Units by Area Median Income (AMI) Level"}
              fieldName={"amiShare"}
              labels={LABELS["amiShare"]}
              color={theme.colors.main}
              readOnly={!!results}
              disabled={false}
            ></ShareInput>
            <ShareInput
              form={form}
              title={"Share of Units by Household Size​"}
              fieldName={"unitMix"}
              labels={LABELS["unit"]}
              color={theme.colors.main}
              readOnly={!!results}
              disabled={false}
            ></ShareInput>
          </Grid.Col>
        </Grid>
      </Container>

      {!results && (
        <Group align="center" mt="xl" pt="md" position="center">
          <Button type="submit" size="lg" loading={sending}>
            {sending ? "Running..." : "Run Analysis"}
          </Button>
        </Group>
      )}

      {results && (
        <Grid mt="xl" ref={resultsRef}>
          <Grid.Col xl={12} sm={12}>
            <div className="instructions">
              <h2>Results</h2>
            </div>
            <MainResult className="instructions">
              {selectedInput === "build" && results.totalGap > 0 && (
                <span style={{ fontWeight: 400 }}>
                  Based on your assumptions, you will need <strong>{formatMoney(results.totalGap, true, 1)}</strong> in
                  local funding to build <strong>{formatNumber(form.values.targets.unitGoal)}</strong> units.
                </span>
              )}
              {selectedInput === "build" && results.totalGap <= 0 && (
                <span style={{ fontWeight: 400 }}>
                  Based on your assumptions, you will <strong>not</strong> need local funding to build{" "}
                  <strong>{formatNumber(form.values.targets.unitGoal)}</strong> units.
                </span>
              )}
              {selectedInput === "spend" && (
                <span>
                  With <strong>{formatMoney(form.values.targets.totalMoneyInvested, true, 1)}</strong> in funding, you
                  could build <strong>{formatNumber(results.unitsBuilt)}</strong> units.
                </span>
              )}
            </MainResult>
            <ResultList className="instructions">
              <li>
                {results.averageGap > 0 && (
                  <div className="text">
                    The average gap in financing per unit that is being filled with local funding is{" "}
                    <strong>{formatMoney(results.averageGap)}</strong> per unit.
                  </div>
                )}
                {results.averageGap <= 0 && <div className="text">There is no gap in financing per unit.</div>}
              </li>
              {selectedInput === "build" && (
                <li>
                  <div className="text">
                    With <strong>{formatNumber(form.values.targets.unitGoal)}</strong> units, you are serving
                    approximately <strong>{formatNumber(results.individualsServed.unitsGoal.adults)}</strong> adults and{" "}
                    <strong>{formatNumber(results.individualsServed.unitsGoal.children)}</strong> children across the
                    City of Dallas.
                  </div>
                </li>
              )}
              {selectedInput === "spend" && (
                <li>
                  <div className="text">
                    With <strong>{formatNumber(results.unitsBuilt)}</strong> units, you are serving approximately{" "}
                    <strong>{formatNumber(results.individualsServed.unitsBuilt.adults)}</strong> adults and{" "}
                    <strong>{formatNumber(results.individualsServed.unitsBuilt.children)}</strong> children across the
                    City of Dallas.
                  </div>
                </li>
              )}
              {selectedInput === "build" && (
                <li>
                  <div className="text">
                    With <strong>{formatMoney(results.totalGap, true, 1)}</strong> in local funding you are leveraging{" "}
                    <strong>{formatMoney(results.totalDollarsLeveraged.unitsGoal, true, 1)}</strong> in federal and
                    philanthropic dollars.
                  </div>
                </li>
              )}
              {selectedInput === "spend" && (
                <li>
                  <div className="text">
                    With <strong>{formatMoney(form.values.targets.totalMoneyInvested, true)}</strong> in local funding
                    you are leveraging <strong>{formatMoney(results.totalDollarsLeveraged.unitsBuilt, true, 1)}</strong>{" "}
                    in federal and philanthropic dollars.
                  </div>
                </li>
              )}
            </ResultList>
          </Grid.Col>
          <Grid.Col lg={6} sm={12} pt="xl">
            <ResultShareChart
              needsMet={selectedInput === "build" ? results.needMet.unitsGoal : results.needMet.unitsBuilt}
              needs={needs}
              colorScheme={report.theme}
              theme={theme}
            />
          </Grid.Col>
          <Grid.Col lg={6} sm={12} pt="xl">
            <ResultPieChart
              colorScheme={report.theme}
              theme={theme}
              unitsByDevType={
                selectedInput === "build" ? results.unitsByDevType.unitsGoal : results.unitsByDevType.unitsBuilt
              }
            />
          </Grid.Col>
          <Grid.Col span={12} pt="xl">
            <ResultBarChart
              colorScheme={report.theme}
              theme={theme}
              unitsByAmiBucket={
                selectedInput === "build" ? results.unitsByAmiBucket.unitsGoal : results.unitsByAmiBucket.unitsBuilt
              }
            />
          </Grid.Col>
        </Grid>
      )}

      {results && (
        <Group align="center" mt="xl" pt="md" position="center">
          <Button size="md" onClick={() => setResults(null)}>
            New Analysis
          </Button>
        </Group>
      )}
    </GapFundingCalculatorWrapper>
  );
}
