"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.COL_YEAR = exports.COL_RELATED_GEO_ID = exports.COL_REL = exports.COL_LONGITUDE = exports.COL_LATITUDE = exports.COL_GEO_ID = exports.COL_DATE = void 0;
const COL_GEO_ID = "geo_id";
exports.COL_GEO_ID = COL_GEO_ID;
const COL_YEAR = "year";
exports.COL_YEAR = COL_YEAR;
const COL_DATE = "date";
exports.COL_DATE = COL_DATE;
const COL_REL = "_rel";
exports.COL_REL = COL_REL;
const COL_RELATED_GEO_ID = "related_geo_id";
exports.COL_RELATED_GEO_ID = COL_RELATED_GEO_ID;
const COL_LATITUDE = "latitude";
exports.COL_LATITUDE = COL_LATITUDE;
const COL_LONGITUDE = "longitude";
exports.COL_LONGITUDE = COL_LONGITUDE;