"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useChartData = useChartData;
var _react = require("react");
var _chartOperations = require("../utils/chart-operations");
var _constants = require("../constants");
function useChartData(chart, filter) {
  let removeNonYears = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  const result = (0, _react.useMemo)(() => {
    var _columns$chart$yCol, _data$, _data$$points$;
    let seriesLabels = _chartOperations.ChartOps.getSeriesLabels(chart, !removeNonYears || chart.seriesCol !== _constants.COL_YEAR);
    let seriesKeys = seriesLabels.map(seriesVal => seriesVal.key);
    const columns = chart.dataView.columns;
    let data = chart.dataView.data;
    data = data.filter(d => seriesKeys.includes(d.seriesKey));
    data.sort((d1, d2) => seriesKeys.findIndex(key => key == d1.seriesKey) - seriesKeys.findIndex(key => key == d2.seriesKey));
    let unfilteredData = data;
    if (chart.filterCol && filter) {
      data = data.map(d => ({
        ...d,
        points: d.points.filter(p => p[chart.filterCol] === filter)
      }));
      if (chart.xCol === chart.filterCol) {
        data = data.map(d => ({
          ...d,
          points: d.points.filter(p => p[chart.xCol] === filter)
        }));
      } else if (chart.seriesCol === chart.filterCol) {
        seriesLabels = seriesLabels.filter(label => label.key === filter);
        seriesKeys = seriesLabels.map(seriesVal => seriesVal.key);
        data = data.filter(d => seriesKeys.includes(d.seriesKey));
      }
    }
    const hasScaleFixed = chart.fixedScale && filter && chart.filterCol;

    // TODO: Pass filtered data instead of chart?
    let xNums = [];
    let xMax;
    let xMin;
    let xKeys = [];
    let xLabels = [];
    if (columns[chart.xCol].type === "quantitative") {
      xNums = data.map(d => d.points.map(p => Number(p[chart.xCol]))).flat().filter(n => !isNaN(n));
      xMax = Math.max(...xNums);
      xMin = Math.min(...xNums);
      if (hasScaleFixed) {
        const nums = unfilteredData.map(d => d.points.map(p => Number(p[chart.xCol]))).flat().filter(n => !isNaN(n));
        xMax = Math.max(...nums);
        xMin = Math.min(...nums);
      }
    } else {
      xLabels = (0, _chartOperations.getColumnLabels)(data, chart.xCol, chart.dataView.columns, removeNonYears);
      if (xLabels && xLabels.length > 0) {
        xKeys = xLabels.map(xLabel => xLabel.key);
        data = data.map(d => ({
          ...d,
          points: d.points.filter(p => xKeys.includes(p[chart.xCol]))
        }));
        if (hasScaleFixed) {
          unfilteredData = unfilteredData.map(d => ({
            ...d,
            points: d.points.filter(p => xKeys.includes(p[chart.xCol]))
          }));
        }
      }
    }
    let yNums = [];
    let yMax;
    let yMin;
    let yKeys = [];
    let yLabels = [];
    const yIsQuantitative = ((_columns$chart$yCol = columns[chart.yCol]) === null || _columns$chart$yCol === void 0 ? void 0 : _columns$chart$yCol.type) === "quantitative" || !isNaN(Number((_data$ = data[0]) === null || _data$ === void 0 ? void 0 : (_data$$points$ = _data$.points[0]) === null || _data$$points$ === void 0 ? void 0 : _data$$points$[chart.yCol]));
    if (yIsQuantitative) {
      yNums = data.map(d => d.points.map(p => Number(p[chart.yCol]))).flat().filter(n => !isNaN(n));
      yMax = Math.max(...yNums);
      yMin = Math.min(...yNums);
      if (hasScaleFixed) {
        const nums = unfilteredData.map(d => d.points.map(p => Number(p[chart.yCol]))).flat().filter(n => !isNaN(n));
        yMax = Math.max(...nums);
        yMin = Math.min(...nums);
      }
    } else {
      yLabels = (0, _chartOperations.getColumnLabels)(data, chart.yCol, chart.dataView.columns);
      if (yLabels && yLabels.length > 0) {
        yKeys = yLabels.map(yLabel => yLabel.key);
        data = data.map(d => ({
          ...d,
          points: d.points.filter(p => yKeys.includes(p[chart.yCol]))
        }));
        if (hasScaleFixed) {
          unfilteredData = unfilteredData.map(d => ({
            ...d,
            points: d.points.filter(p => yKeys.includes(p[chart.yCol]))
          }));
        }
      }
    }
    return {
      data,
      unfilteredData,
      seriesLabels,
      seriesKeys,
      xLabels,
      xNums,
      xMin,
      xMax,
      xKeys,
      yLabels,
      yNums,
      yKeys,
      yMax,
      yMin
    };
  }, [chart, filter]);
  return result;
}