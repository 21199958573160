import type { WidgetData } from "@api/reports/output/output.types";
import React, { useEffect, useMemo } from "react";
import { WidgetProps } from "../types";
import { ParentFilterPanel } from "./ParentFilter.styled";
import { MantineProvider, Select, SelectItem } from "@mantine/core";

type ParentFilterProps = Omit<WidgetProps, "widget"> & {
  widget: Omit<WidgetData, "componentProps"> & {
    componentProps: {
      column: string;
      display: "auto" | "dropdown";
    };
  };
};

export default function ParentFilter({ widget, theme, report, filterState }: ParentFilterProps) {
  const [filter, setFilter] = filterState;

  const options = useMemo(() => {
    const chapter = report.chapters.find((chapter) =>
      chapter.sections.find((section) => section.widgets?.find((sectionWidget) => sectionWidget.id === widget.id))
    );
    const section = chapter?.sections.find((section) =>
      section.widgets?.find((sectionWidget) => sectionWidget.id === widget.id)
    );
    const widgetCol = widget.componentProps.column;
    const charts = section.charts.filter((chart) => chart.filterDisplay === "parent" && widgetCol === chart.filterCol);
    const options: { [filterCol: string]: SelectItem[] } = {};
    charts.forEach((chart) => {
      const column = chart.dataView.columns[chart.filterCol];

      let categories = Object.keys(column.categories)
        .filter((category) => column.categories[category].visible)
        .sort((a, b) => column.categories[a].position - column.categories[b].position);

      if (!options[chart.filterCol]) {
        options[chart.filterCol] = [];
      }
      categories.forEach((category) => {
        if (!options[chart.filterCol].find((option) => option.value === category)) {
          options[chart.filterCol].push({ value: category, label: column.categories[category].label });
        }
      });
    });
    return options;
  }, [report, widget]);

  useEffect(() => {
    const filterChange: Record<string, string> = {};
    Object.keys(options).forEach((filterCol) => {
      const values = options[filterCol].map((item) => item.value);
      if (!values.includes(filter[filterCol])) {
        filterChange[filterCol] = values[0];
      }
    });
    if (Object.keys(filterChange).length > 0) {
      setFilter((prevFilter) => ({ ...prevFilter, ...filterChange }));
    }
  }, [options, filter, setFilter]);

  const handleFilterChange = (filterCol: string, value: string) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, [filterCol]: value };
    });
  };

  // TODO: We may not need options to be a record
  if (Object.keys(options).length === 0) {
    return null;
  }

  return (
    <MantineProvider
      theme={{
        colors: {
          // @ts-ignore
          blue: Array.from({ length: 10 }, (val, idx) => theme.colors.main),
        },
      }}
    >
      <ParentFilterPanel>
        {Object.keys(options).map((filterCol) => (
          <div key={filterCol}>
            <Select
              data={options[filterCol]}
              value={filter[filterCol]}
              onChange={(value) => handleFilterChange(filterCol, value)}
              searchable={options[filterCol].length > 20}
            />
          </div>
        ))}
      </ParentFilterPanel>
    </MantineProvider>
  );
}
