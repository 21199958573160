// import { Theme } from "@emotion/react";

// const MainTheme: Theme = {
//   colors: {
//     main: "#00316e",
//     mainBright: "#0c599f",
//     text: "#1A2D3F",
//   },
//   charts: {
//     fontFamily: "Work Sans, Nunito Sans, sans-serif",
//   },
//   breaks: {
//     small: "768px",
//     medium: "1024px",
//     big: "1280px",
//   },
// };

import { MantineThemeOverride } from "@mantine/core";

export const MainTheme: MantineThemeOverride = {
  fontFamily: "Work Sans, Nunito Sans, sans-serif",
  cursorType: "pointer",
  primaryColor: "report",
  primaryShade: 0,
  black: "#1A2D3F",
  colors: {
    report: ["#FFF", "#DDD", "#BBB", "#999", "#777", "#555", "#333", "#222", "#111", "#000"],
  },
  breakpoints: {
    xs: "500px",
    sm: "768px",
    md: "1024px",
    lg: "1280px",
    xl: "1440px",
  },
  globalStyles: (theme) => ({
    "*, *::before, *::after": {
      boxSizing: "border-box",
    },

    "*": {
      /* Remove default margin on everything */
      margin: 0,
      /* Remove default padding on everything */
      padding: 0,
      lineHeight: "calc(0.25rem + 1em + 0.25rem)",
    },

    ":not(fieldset, progress, meter)": {
      borderWidth: 0,
      borderStyle: "solid",
      backgroundOrigin: "border-box",
      backgroundRepeat: "no-repeat",
    },

    html: {
      /* Allow percentage-based heights in the application */
      blockSize: "100%",
      /* Making sure text size is only controlled by font-size */
      webkitTextSizeAdjust: "none",
      scrollBehavior: "smooth",
    },

    body: {
      ...theme.fn.fontStyles(),
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
      color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
      lineHeight: theme.lineHeight,
      textRendering: "optimizeSpeed",
      minBlockSize: "100%",
    },

    svg: {
      stroke: "none",
      fill: "currentColor",
    },

    "p, h1, h2, h3, h4, h5, h6": {
      overflowWrap: "break-word",
    },
  }),
};
