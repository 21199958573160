"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MarkLabel = exports.FilterContainer = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _templateObject, _templateObject2;
const MarkLabel = _styled.default.span(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  white-space: nowrap;\n"])));
exports.MarkLabel = MarkLabel;
const FilterContainer = _styled.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  text-align: center;\n"])));
exports.FilterContainer = FilterContainer;