import ReactMarkdown from "react-markdown";
import type { PortalReport, ProjectWithReportsOut } from "@api/projects/projects.types";
import {
  Button,
  Divider,
  Group,
  MantineProvider,
  MantineThemeOverride,
  MultiSelect,
  Select,
  Stack,
} from "@mantine/core";
import React, { useCallback, useEffect, useState } from "react";
import { ThemeContext } from "./Report";
import { MainContainer, ScreenLayout } from "./ScreenReport.styled";
import IntroductionHeader from "./components/IntroductionHeader";
import { MainContent, ReportOption, ReportTitle } from "./Portal.styled";
import { useApi } from "../../hooks/useApi";
import type { SearchResult } from "@api/sources/search-index";
import { AxiosError } from "axios";
import GeographyAutocomplete from "./components/GeographyAutocomplete";
import { useNavigate } from "react-router-dom";
import type { SourceColumnCategory } from "@api/sources/sources.types";

type PortalProps = {
  project: ProjectWithReportsOut;
};

type SearchQuery = {
  term: string;
  mode: "autocomplete" | "geoIds";
};

type ReportGeoData = {
  [key: number]: {
    main?: SearchResult[];
    custom?: SearchResult[];
  };
};

type SelectedData = {
  [key: number]: {
    main?: string;
    custom?: string[];
    filters?: {
      [idx: number]: string;
    };
  };
};

type ColumnData = {
  [sourceId: string]: SourceColumnCategory[];
};

export default function Portal({ project }: PortalProps) {
  const [geoData, setGeoData] = useState<ReportGeoData>({});
  const [columnData, setColumnData] = useState<ColumnData>({});
  const [selected, setSelected] = useState<SelectedData>({});
  const navigate = useNavigate();

  const appTheme = project.app.configuration.theme;
  const mainColor = appTheme?.colors.main;
  const theme: MantineThemeOverride = mainColor && {
    colors: {
      report: [
        mainColor,
        mainColor,
        mainColor,
        mainColor,
        mainColor,
        mainColor,
        mainColor,
        mainColor,
        mainColor,
        mainColor,
      ],
    },
  };

  const { loading: lookingUpGeographies, callApi: lookupGeographies } = useApi<
    SearchResult[],
    { reportId: string },
    SearchQuery
  >("/reports/:reportId/geography-lookup", "get", true);

  const { callApi: getCategories } = useApi<SourceColumnCategory[], { sourceId: string }, { column: string }>(
    "/sources/:sourceId/categories",
    "get",
    true
  );

  const updateGeoData = useCallback(
    async (portalReport: PortalReport, idx: number, part: "main" | "custom") => {
      if (portalReport.geographies[part].selectType === "fixed" && geoData[idx]?.[part] === undefined) {
        const data = await lookupGeographies({
          params: {
            reportId: portalReport.reportId.toString(),
          },
          // @ts-ignore
          query: { term: portalReport.geographies[part].geoIds.join(","), mode: "geoIds" },
        });
        if (typeof data === "object" && !(data instanceof AxiosError)) {
          setGeoData((geoData) => {
            return {
              ...geoData,
              [idx]: {
                ...geoData[idx],
                [part]: data,
              },
            };
          });
        }
      }
    },
    [geoData, lookupGeographies]
  );

  useEffect(() => {
    if (project.app.configuration.landingPage.behavior !== "portal") {
      return;
    }
    project.app.configuration.landingPage.configuration.reports.map(async (portalReport, idx) => {
      await updateGeoData(portalReport, idx, "main");
      await updateGeoData(portalReport, idx, "custom");
      portalReport.geographies.filters.map(async (filter) => {
        const categories = await getCategories({
          params: {
            sourceId: filter.sourceId.toString(),
          },
          query: { column: filter.column },
        });
        if (typeof categories === "object" && !(categories instanceof AxiosError)) {
          setColumnData((columnData) => {
            return {
              ...columnData,
              [filter.sourceId.toString()]: categories,
            };
          });
        }
      });
    });
  }, [updateGeoData, project, getCategories]);

  if (project.app.configuration.landingPage.behavior !== "portal") {
    return null;
  }

  function handleReportClick(idx: number) {
    if (project.app.configuration.landingPage.behavior !== "portal") {
      return;
    }
    const reportId = project.app.configuration.landingPage.configuration.reports[idx].reportId;
    if (reportId && selected[idx]?.main) {
      const custom = selected[idx].custom ? `custom=${selected[idx].custom?.join(",") ?? ""}` : "";
      let reportFilters = project.app.configuration.landingPage.configuration.reports[idx].geographies.filters;
      let filters = "";
      if (reportFilters) {
        reportFilters = reportFilters.filter((filter, filterIdx) => selected[idx].filters?.[filterIdx]);
        if (reportFilters.length > 0) {
          filters = `filters=${encodeURIComponent(
            reportFilters.map((filter, filterIdx) => `${filter.column}="${selected[idx].filters[filterIdx]}"`).join(",")
          )}`;
        }
      }
      navigate(`/report/${reportId}/${selected[idx].main}?${custom}&${filters}`);
    }
  }

  function handleFilterChange(reportIdx: number, filterIdx: number, value: string) {
    setSelected((selected) => ({
      ...selected,
      [reportIdx]: {
        ...selected[reportIdx],
        filters: {
          ...selected[reportIdx]?.filters,
          [filterIdx]: value,
        },
      },
    }));
  }

  return (
    <MantineProvider theme={theme}>
      <ThemeContext.Provider value={appTheme}>
        <ScreenLayout>
          <IntroductionHeader project={project} />
          <MainContainer hasNav={false}>
            <MainContent>
              <ReactMarkdown className="instructions">
                {project.app.configuration.landingPage.configuration.content}
              </ReactMarkdown>
              <Stack mt="xl">
                {project.app.configuration.landingPage.configuration.reports.map((portalReport, idx) => {
                  const report = project.reports.find((report) => report._id === portalReport.reportId.toString());
                  if (!report) {
                    return null;
                  }
                  return (
                    <ReportOption key={idx}>
                      <ReportTitle>{report.title}</ReportTitle>
                      {report.description && <p>{report.description}</p>}
                      <Divider my="md" />
                      <Group grow>
                        {portalReport.geographies.main.selectType === "fixed" && (
                          <Select
                            data={portalReport.geographies.main.geoIds.map((id) => ({
                              value: id,
                              label: geoData[idx]?.main?.find((geo) => geo.geo_id === id)?.geo_name ?? id,
                            }))}
                            placeholder={"Select a geography"}
                            label={"Select Main Location"}
                            value={selected[idx]?.main}
                            onChange={(value) =>
                              setSelected((selected) => ({ ...selected, [idx]: { ...selected[idx], main: value } }))
                            }
                          />
                        )}
                        {portalReport.geographies.main.selectType === "lookup" && (
                          <GeographyAutocomplete
                            sourceId={portalReport.geographies.main.sourceId.toString()}
                            label={"Select a geography"}
                            onValue={(results) =>
                              setSelected((selected) => ({
                                ...selected,
                                [idx]: { ...selected[idx], main: results[0] },
                              }))
                            }
                            multiple={false}
                          />
                        )}
                        {portalReport.geographies.custom.selectType === "fixed" && (
                          <MultiSelect
                            data={portalReport.geographies.custom.geoIds.map((id) => ({
                              value: id,
                              label: geoData[idx]?.custom?.find((geo) => geo.geo_id === id)?.geo_name ?? id,
                            }))}
                            placeholder={"Select a geography"}
                            label={"Compare to Other Locations"}
                            value={selected[idx]?.custom}
                            onChange={(value) =>
                              setSelected((selected) => ({ ...selected, [idx]: { ...selected[idx], custom: value } }))
                            }
                          />
                        )}
                        {portalReport.geographies.custom.selectType === "lookup" && (
                          <GeographyAutocomplete
                            sourceId={portalReport.geographies.custom.sourceId.toString()}
                            label={"Compare to Other Locations"}
                            onValue={(results) =>
                              setSelected((selected) => ({ ...selected, [idx]: { ...selected[idx], custom: results } }))
                            }
                            multiple={true}
                          />
                        )}
                        {portalReport.geographies.filters.map((filter, filterIdx) => (
                          <Select
                            key={filterIdx}
                            data={
                              columnData[filter.sourceId.toString()]
                                ?.filter((category) => category.enabled && !category.aggregate)
                                ?.map((category) => ({
                                  value: category.value,
                                  label: category.label || category.value,
                                })) || []
                            }
                            placeholder={"Select"}
                            label={filter.name}
                            value={selected[idx]?.filters?.[filterIdx]}
                            onChange={(value) => handleFilterChange(idx, filterIdx, value)}
                          />
                        ))}
                      </Group>

                      <Button mt="xl" color="report" onClick={() => handleReportClick(idx)}>
                        See Report
                      </Button>
                    </ReportOption>
                  );
                })}
              </Stack>
            </MainContent>
          </MainContainer>
        </ScreenLayout>
      </ThemeContext.Provider>
    </MantineProvider>
  );
}
