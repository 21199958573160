import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AsideZone, DraftAlert, LoadingScreen, MainContainer, ScreenLayout } from "./ScreenReport.styled";
import { GridLoader } from "react-spinners";
import type { ReportData, ReportSkeleton } from "@api/reports/output/output.types";
import { MantineProvider, MantineThemeOverride } from "@mantine/core";
import { useApi } from "../../hooks/useApi";
import { DEFAULT_PAGE_ID } from "../../App.constants";
import { FetchPageBody, ThemeContext } from "./Report";
import { chapterHasContent } from "./utils";
import ScreenChapter from "./components/ScreenChapter";
import { MainContent } from "./Report.styled";
import ScreenHeader from "./components/ScreenHeader";
import ReportMenu from "./components/ReportMenu";

type ScreenDashboardProps = {
  geoId: string;
  reportId: string;
  custom: string[];
  exclude: string[];
  filters: string[];
  pageId?: string;
  baseUrl: string;
};

const HEADER_OFFSET = 64;
const LAYOUT = "screen";

export default function ScreenReport({
  reportId,
  geoId,
  pageId,
  custom,
  exclude,
  filters,
  baseUrl,
}: ScreenDashboardProps) {
  const [selectedSectionId, setSelectedSectionId] = useState(null);
  const {
    data: skeleton,
    loading: skeletonLoading,
    callApi: fetchSkeleton,
  } = useApi<ReportSkeleton, { reportId: string; geoId: string }>("/reports/:reportId/output/:geoId/skeleton");

  const {
    data: report,
    error,
    loading: isFetching,
    callApi: fetchPage,
  } = useApi<ReportData, { reportId: string; geoId: string }, FetchPageBody>(
    "/reports/:reportId/output/:geoId",
    "post"
  );

  pageId = useMemo(() => {
    if (pageId) {
      return pageId;
    }
    if (skeleton) {
      return skeleton.pages[0]?.id || DEFAULT_PAGE_ID;
    }
  }, [skeleton, pageId]);

  useEffect(() => {
    fetchSkeleton({ params: { reportId, geoId } });
  }, [fetchSkeleton, reportId, geoId]);

  useEffect(() => {
    // @ts-ignore
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [pageId]);

  useEffect(() => {
    if (skeleton) {
      const selection = skeleton.chapters.filter((chapter) => chapter.pageId === pageId).map((chapter) => chapter.id);
      const data = { customGeoIds: custom, exclude, selection, filters };
      void fetchPage({ params: { reportId, geoId }, data });
    }
  }, [skeleton, fetchPage, reportId, geoId, custom, exclude, filters, pageId]);

  const headerRef = useRef<HTMLDivElement>(null);

  const handleSectionChange = useCallback((sectionId: string) => {
    setTimeout(() => {
      setSelectedSectionId(sectionId);
    }, 0);
  }, []);

  const pageChapters = useMemo(() => {
    return report?.chapters.filter((chapter) => chapter.pageId === pageId) || [];
  }, [report, pageId]);

  const skeletonChapters = skeleton?.chapters.filter((chapter) => chapter.pageId === pageId) || [];

  useEffect(() => {
    if (report && pageChapters[0]?.sections[0].id) {
      setSelectedSectionId(pageChapters[0].sections[0].id);
    }
    if (report) {
      document.title = `${report.geography.name} - ${report.title} - Web Report - HR&A Advisors `;
    }
  }, [report, pageChapters]);

  const handleSectionSelect = (sectionId: string, smooth = true) => {
    const element = document.getElementById(sectionId);
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition - headerRef.current.offsetHeight - HEADER_OFFSET;
    window.scrollBy({
      top: offsetPosition,
      // @ts-ignore
      behavior: smooth ? "smooth" : "instant",
    });
  };

  const appTheme = skeleton?.project.app.configuration?.theme;
  const mainColor = appTheme?.colors.main;

  const content = report && !isFetching && (
    <MainContent layout={LAYOUT}>
      {pageChapters.filter(chapterHasContent("screen")).map((chapter) => (
        <ScreenChapter
          chapter={chapter}
          key={chapter.id}
          onSectionChange={handleSectionChange}
          report={report}
          customGeoIds={custom}
          theme={appTheme}
        ></ScreenChapter>
      ))}
    </MainContent>
  );

  const theme: MantineThemeOverride = mainColor && {
    colors: {
      report: [
        mainColor,
        mainColor,
        mainColor,
        mainColor,
        mainColor,
        mainColor,
        mainColor,
        mainColor,
        mainColor,
        mainColor,
      ],
    },
  };

  if (report) {
    //report.theme.colors.main = mainColor;
  }

  const hasNav = skeleton?.pages.length > 2;

  return (
    <MantineProvider theme={theme}>
      <ThemeContext.Provider value={appTheme}>
        <ScreenLayout>
          <ScreenHeader
            report={skeleton}
            geoId={geoId}
            headerRef={headerRef}
            isLoading={isFetching}
            pageId={pageId}
            custom={custom}
            exclude={exclude}
            filters={filters}
            baseUrl={baseUrl}
          />
          {(pageChapters?.length > 0 || skeletonChapters.length > 0) && (
            <MainContainer hasNav={hasNav}>
              {report?.isDraft && (
                <DraftAlert>
                  This is a draft preview. Charts with errors or missing data are visible. Save the changes to see the
                  final report.
                </DraftAlert>
              )}
              <AsideZone hasTopBar={hasNav}>
                {/* <ComparisonInput /> */}
                <ReportMenu
                  report={isFetching ? skeleton : report}
                  pageId={pageId}
                  isLoading={isFetching}
                  sectionId={selectedSectionId}
                  onSectionSelect={handleSectionSelect}
                ></ReportMenu>
              </AsideZone>
              {content}
            </MainContainer>
          )}
          {(isFetching || skeletonLoading) && (
            <LoadingScreen>
              <GridLoader size={30} color={appTheme?.colors.main || "#ccc"} />
            </LoadingScreen>
          )}
          {error && <LoadingScreen>{error.toString()}</LoadingScreen>}
          {report && report.chapters?.length === 0 && (
            <LoadingScreen>There is no data for {skeleton.geography.name} in this section.</LoadingScreen>
          )}
        </ScreenLayout>
      </ThemeContext.Provider>
    </MantineProvider>
  );
}
