import styled from "@emotion/styled";
import { ReportLayout } from "./types";

export const MainContent = styled.main<{ layout: ReportLayout }>`
  padding-bottom: ${(props) => (props.layout === "screen" ? "5em" : "0")};
  padding-top: ${(props) => (props.layout === "screen" ? "3em" : "0")};
  position: relative;
  max-width: ${({ theme }) => theme.breakpoints.lg};
  margin: auto;
  margin-top: ${(props) => (props.layout === "screen" ? "auto" : "0")};

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-left: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding-top: 2em;
    margin-top: 0;
  }
`;
