import styled from "@emotion/styled";
import selectedIcon from "../../../assets/nav-selected.svg";

export const ReportNav = styled.nav`
  width: 100%;
  box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 22%);
  background: #f0f4f9;
  z-index: 1;
  border-radius: 0.5em;

  > ul {
    padding: 1.1em 0;
  }

  a {
    color: ${(props) => props.theme.colors.text};
    text-decoration: none;
    display: block;
    font-size: 1.1rem;
    padding: 0.7em 0 0.7em 2.1em;
    font-weight: 400;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

export const ReportNavItem = styled.li`
  display: block;
  font-weight: 500;
  cursor: pointer;
  font-size: 0.9rem;
  line-height: 1.1rem;

  &.selected {
    background: url(${selectedIcon}) no-repeat left 0.5em top 1.2em;
    background-size: 22px;
  }

  &:hover {
    font-weight: 600;
  }
`;

export const ReportNavSections = styled.ul<{ isLast: boolean }>`
  padding: 0.5em 0;
  display: none;
  .selected & {
    display: block;
    max-height: 329px;
    overflow: auto;
    border-bottom: ${(props) => (props.isLast ? "none" : "rgb(225, 229, 235) 2px solid")};
  }
`;

export const ReportNavTitle = styled.div`
  font-size: 1.1rem;
  padding: 0.6em 1em;

  .selected & {
    font-weight: 600;
    background: #e1e5eb;
  }
`;

export const ReportSectionItem = styled.li<{ selectedColor: string }>`
  display: block;
  font-weight: 400;
  padding: 0.4em 2.5em;
  cursor: pointer;

  &.selected {
    font-weight: 600;
    background: url(${selectedIcon}) no-repeat left 0.7em top 0.4em;
    background-size: 22px;
    color: ${(props) => props.selectedColor};
  }

  &:hover {
    font-weight: 600;
  }
`;
