import React from "react";
import { ChapterLogo, ChapterTitle, ChapterWrapper } from "./Chapter.styled";
import type { ChapterData, ReportData } from "@api/reports/output/output.types";
import PrintSection from "./PrintSection";

type ReportProps = {
  chapter: ChapterData;
  report: ReportData;
};

function PrintChapter({ chapter, report }: ReportProps) {
  const appTheme = report.project.app.configuration.theme;
  return (
    <ChapterWrapper id={chapter.id}>
      <ChapterTitle color={appTheme.colors.main}>
        <ChapterLogo>
          <img src={appTheme.logo} alt="logo" />
        </ChapterLogo>
        <h1>{chapter.title}</h1>
        <h2>{report.geography.name}</h2>
        <h3>{report.title}</h3>
      </ChapterTitle>
      {chapter.sections.map((section) => (
        <PrintSection section={section} report={report} key={section.id} />
      ))}
    </ChapterWrapper>
  );
}

export default React.memo(PrintChapter);
