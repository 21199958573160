"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ZoomButton = exports.ScatterPlotGroup = exports.AxisTitles = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _templateObject, _templateObject2, _templateObject3;
const ScatterPlotGroup = _styled.default.g(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  text {\n    font-size: 0.5em;\n    fill: #333;\n  }\n  .x-zero, .y-zero {\n    stroke-dasharray: 5,5;\n  }\n"])));
exports.ScatterPlotGroup = ScatterPlotGroup;
const AxisTitles = _styled.default.g(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  font-size: 0.8em;\n  fill: #666;\n"])));
exports.AxisTitles = AxisTitles;
const ZoomButton = _styled.default.text(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  font-size: 0.6em;\n  text-decoration: underline;\n  cursor: pointer;\n  display: none;\n"])));
exports.ZoomButton = ZoomButton;