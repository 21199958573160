import styled from "@emotion/styled";

export const ReportOption = styled.div`
  background-color: #eee;
  padding: 1rem;
  border-radius: 0.5rem;
`;

export const ReportTitle = styled.h2`
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const MainContent = styled.main`
  padding-bottom: 5em;
  padding-top: 3em;
  position: relative;
  max-width: ${({ theme }) => theme.breakpoints.lg};
  margin: auto;
  margin-top: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-left: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding-top: 2em;
    margin-top: 0;
  }

  .instructions {
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-bottom: 0.8rem;
      color: ${(props) => props.color};
      font-weight: 500;
    }
    h1 {
      font-size: 1.8rem;
    }
    h2 {
      font-size: 1.5rem;
    }
    h3 {
      font-size: 1.3rem;
    }
    h4,
    h5,
    h6 {
      font-size: 1rem;
    }
    p,
    ul {
      margin-bottom: 0.8rem;
    }
    p,
    li {
      font-weight: 300;
      font-size: 1.05rem;
      line-height: 1.55rem;
      color: #111;
      list-style-type: circle;
    }

    li {
      margin-left: 16px;
    }

    strong {
      color: ${(props) => props.color};
      font-weight: 600;
    }
    em {
      font-weight: 400;
      font-style: normal;
    }
    a {
      color: ${(props) => props.color};
    }
  }
`;
