"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SankeyDiagramTextGroup = exports.SankeyDiagramGroup = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _templateObject, _templateObject2;
const SankeyDiagramGroup = _styled.default.g(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mix-blend-mode: multiply;\n  stroke: #000;\n  stroke-opacity: 0.2;\n  fill: none;\n\n  .link:hover {\n    stroke-opacity: .4;\n  }\n"])));
exports.SankeyDiagramGroup = SankeyDiagramGroup;
const SankeyDiagramTextGroup = _styled.default.g(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  text {\n    fill: #333;\n  }\n"])));
exports.SankeyDiagramTextGroup = SankeyDiagramTextGroup;