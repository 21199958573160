import React, { useState } from "react";
import { SectionDiv, WidgetContainerWrapper } from "./ScreenSection.styled";
import {
  SectionChartGrid,
  ChartContainerWrapper,
  SectionSummary,
  SectionTitle,
  SectionWidgetGrid,
} from "./Section.styled";
import { useInView } from "react-intersection-observer";
import type { ReportData, SectionData } from "@api/reports/output/output.types";
import { ChartContainer, Markdown } from "@hra/hna-charts";
import { WidgetContainer } from "../widgets/WidgetContainer";
import type { WebReportConfiguration } from "@api/projects/projects.types";

type ScreenSectionProps = {
  onSectionChange: (sectionId: string) => void;
  section: SectionData;
  report: ReportData;
  customGeoIds?: string[];
  theme: WebReportConfiguration["theme"];
};

const LAYOUT = "screen";

export default function ScreenSection({ onSectionChange, section, report, customGeoIds, theme }: ScreenSectionProps) {
  const filterState = useState<Record<string, string>>({});
  const [sectionCharts, setSectionCharts] = useState(section.charts);

  const { ref, inView } = useInView({
    threshold: 0.33,
  });

  if (inView) {
    onSectionChange(section.id);
  }

  return (
    <SectionDiv ref={ref} id={section.id}>
      <SectionTitle color={theme.colors.main} layout={LAYOUT}>
        {section.title}
      </SectionTitle>
      {section.summary.text && (
        <SectionSummary layout={LAYOUT}>
          <Markdown text={section.summary.text} />
        </SectionSummary>
      )}

      <SectionWidgetGrid>
        {section.widgets.map((widget) => (
          <WidgetContainerWrapper key={widget.id}>
            <WidgetContainer
              widget={widget}
              report={report}
              filterState={filterState}
              setCharts={setSectionCharts}
              customGeoIds={customGeoIds}
              theme={theme}
            />
          </WidgetContainerWrapper>
        ))}
      </SectionWidgetGrid>

      <SectionChartGrid layout={LAYOUT} isFirstPage={true}>
        {sectionCharts.map((chart) => (
          <ChartContainerWrapper spanRow={chart.spanRow} key={chart.id} layout={LAYOUT}>
            <ChartContainer chart={chart} layout={LAYOUT} report={report} theme={theme} parentFilter={filterState[0]} />
          </ChartContainerWrapper>
        ))}
      </SectionChartGrid>
    </SectionDiv>
  );
}
