"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DonutGroup = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _templateObject;
const DonutGroup = _styled.default.g(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  .semitransparent {\n    transition: none !important;\n    opacity: 0.2 !important;\n  }\n\n  text.inside-label {\n    font-size: clamp(0.65rem, 0.9vw, 0.9rem);\n    fill: white;\n  }\n\n  .arc {\n    opacity: 0;\n    transition: opacity 0.4s;\n    transition-delay: var(--transition-delay);\n\n    &.full {\n      opacity: 1;\n    }\n\n    &.active {\n      transition: none;\n    }\n  }\n"])));
exports.DonutGroup = DonutGroup;