import styled from "@emotion/styled";

export const SectionDiv = styled.article`
  page-break-before: always;
  padding-bottom: 5%;
  background: white;
`;

export const WidgetContainerWrapper = styled.div`
  flex-grow: 1;

  @media (max-width: 768px) {
    min-width: 100%;
  }
`;
