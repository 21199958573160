import React, { useLayoutEffect, useRef } from "react";
import * as d3 from "d3";
import type { ThemeData } from "@api/reports/output/output.types";
import { ChartTitle, ChartWrapper } from "@hra/hna-charts";
import { formatNumber } from "./util";
import type { WebReportTheme } from "@api/projects/projects.types";

type ResultBarChartProps = {
  colorScheme: ThemeData;
  theme: WebReportTheme;
  unitsByAmiBucket: {
    ami30: number;
    ami50: number;
    ami60: number;
    ami80: number;
    marketRate: number;
  };
};

const MARGIN = { top: 30, bottom: 50, left: 0, right: 0 };
const BAR_GAP = 0.25;
const Y_AXIS_PADDING = 0.05;
const AMI_BUCKETS = {
  ami30: "30% AMI",
  ami50: "50% AMI",
  ami60: "60% AMI",
  ami80: "80% AMI",
  marketRate: "Market Rate",
};

export default function ResultBarChart({ colorScheme, theme, unitsByAmiBucket }: ResultBarChartProps) {
  const svgRef = useRef<SVGSVGElement>(null);

  const firstMainElement = document.querySelector("body main");
  const width = Math.min(600, firstMainElement?.clientWidth || window.innerWidth);
  const height = 320;

  const boundsWidth = width - MARGIN.right - MARGIN.left;
  const boundsHeight = height - MARGIN.top - MARGIN.bottom;

  const points = Object.keys(unitsByAmiBucket).map((amiBucket) => ({
    value: unitsByAmiBucket[amiBucket as keyof typeof unitsByAmiBucket],
    label: AMI_BUCKETS[amiBucket as keyof typeof AMI_BUCKETS],
    key: amiBucket,
  }));

  const xScale = d3.scaleBand().domain(Object.keys(AMI_BUCKETS)).range([0, width]).padding(BAR_GAP);

  const yMax = Math.max(...points.map((p) => p.value));
  const yPadding = yMax * Y_AXIS_PADDING;
  const maxDomain = yMax + yPadding;

  const yScale = d3.scaleLinear().domain([0, maxDomain]).range([boundsHeight, 0]);

  useLayoutEffect(() => {
    if (svgRef.current === null || !boundsHeight) {
      return;
    }
    const xAxisScale = xScale.domain(
      Object.keys(AMI_BUCKETS).map((xVal) => AMI_BUCKETS[xVal as keyof typeof AMI_BUCKETS])
    );
    const xAxis = d3.axisBottom(xAxisScale).tickSize(0).tickPadding(12);

    d3.select(svgRef.current)
      .select<SVGSVGElement>(".x-axis")
      .attr("transform", `translate(0, ${yScale(0) + MARGIN.top})`)
      .call(xAxis);

    d3.select(svgRef.current).selectAll(".x-axis .tick line").attr("transform", `translate(0, 0)`);
  }, [boundsHeight, xScale, yScale]);

  return (
    <ChartWrapper spanRow={false}>
      <ChartTitle color={theme.colors.main} layout="screen">
        Units Built by AMI Level
      </ChartTitle>
      <svg
        ref={svgRef}
        width={width}
        height={height}
        style={{
          background: "white",
          borderRadius: "8px",
          display: "block",
          margin: "auto",
          marginTop: "1.5rem",
          marginBottom: "1.5rem",
        }}
        viewBox={`0 0 ${width} ${height}`}
      >
        <g width={boundsWidth} height={boundsHeight} transform={`translate(${MARGIN.left}, ${MARGIN.top})`}>
          {points.map((p, idx) => (
            <g key={p.key}>
              <rect
                x={xScale(p.key)}
                y={yScale(Math.max(p.value, 0))}
                width={xScale.bandwidth()}
                height={boundsHeight - yScale(Math.abs(p.value))}
                fill={colorScheme.colors.schemes.categorical[0]}
              ></rect>
              <text
                className="bar-label"
                textAnchor="middle"
                dx={xScale.bandwidth() / 2}
                dy={p.value >= 0 ? -6 : 14}
                x={xScale(p.key)}
                y={yScale(p.value)}
              >
                {formatNumber(p.value)}
              </text>
            </g>
          ))}
        </g>
        <g className="x-axis"></g>
      </svg>
    </ChartWrapper>
  );
}
