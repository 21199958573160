"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LineChartGroup = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _templateObject;
const LineChartGroup = _styled.default.g(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  .line {\n    opacity: 0;\n    //transition: opacity 100ms 0.2s;\n\n    &.animated {\n      stroke-dasharray: var(--path-length);\n      stroke-dashoffset: var(--path-length);\n      animation: dash 800ms linear forwards;\n      animation-iteration-count: 1;\n      opacity: 1;\n    }\n\n    &.full {\n      opacity: 1;\n    }\n\n    @keyframes dash {\n      100% {\n        stroke-dashoffset: 0;\n      }\n    }\n  }\n\n  .semitransparent {\n    opacity: 0.2 !important;\n  }\n\n  .line-label {\n    font-size: clamp(0.65rem, 0.9vw, 0.85rem);\n  }\n\n  circle {\n    transition: opacity 0.3s 0.8s;\n    opacity: 0;\n    fill-opacity: 0;\n\n    &.full {\n      opacity: 1;\n    }\n\n    &.visible {\n      fill-opacity: 0.3;\n    }\n  }\n"])));
exports.LineChartGroup = LineChartGroup;