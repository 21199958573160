export function formatMoney(number: number, compact = false, digits: number = 0) {
  if (number === 0) {
    return "$0";
  }
  if (!number) {
    return "";
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: digits,
    notation: compact ? "compact" : "standard",
  });
  return formatter.format(number);
}

export function formatNumber(number: number, compact = false, digits = 0) {
  if (number === 0) {
    return "0";
  }
  if (!number) {
    return "";
  }
  if (!compact) {
    const formatter = new Intl.NumberFormat("en-US", {
      notation: "standard",
      minimumFractionDigits: 0,
      maximumFractionDigits: digits,
    });
    return formatter.format(number);
  } else {
    const formatter = new Intl.NumberFormat("en-US", {
      notation: compact ? "compact" : "standard",
      minimumFractionDigits: 0,
      maximumFractionDigits: digits,
    });
    return formatter.format(number);
  }
}
