import styled from "@emotion/styled";
import { ReportLayout } from "../types";

export const SectionTitle = styled.h2<{ color: string; layout: ReportLayout }>`
  font-size: 2rem;
  color: ${(props) => props.color};
  font-weight: 500;
  margin-bottom: 0.7rem;
  letter-spacing: -0.3px;
  text-align: "left";
`;

export const SectionSummary = styled.div<{ layout: ReportLayout }>`
  font-size: 1.1em;
  font-weight: 300;
  text-align: "left";
  margin: 0 auto;
  margin-top: ${(props) => (props.layout === "screen" ? "1.7rem" : "0.7rem")};
  line-height: 1.2em;
  width: ${(props) => (props.layout === "screen" ? "auto" : "100%")};

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 0.7rem;
    color: ${(props) => props.color};
  }
  h1 {
    font-size: 1.4rem;
  }
  h2 {
    font-size: 1.3rem;
  }
  h3 {
    font-size: 1.2rem;
  }
  h4,
  h5,
  h6 {
    font-size: 1rem;
  }
  p {
    margin-bottom: 0.9rem;
    font-weight: 300;
    font-size: 1.05rem;
    line-height: 1.55rem;
    color: #111;
  }
  strong {
    color: ${(props) => props.color};
  }
  em {
    font-weight: 400;
    font-style: normal;
  }
  a {
    color: ${(props) => props.color};
  }
  ol,
  ul {
    margin-left: 1.5rem;
    font-size: 1.05rem;
    font-weight: 300;
    line-height: 1.55rem;
    color: #111;
  }
  li {
    margin-bottom: 0.1rem;
  }
  img {
    display: block;
    max-width: 100%;
    margin: 16px auto;
  }
  table {
    border-collapse: collapse;
    border: solid 1px #ccc;
    td,
    th {
      border: solid 1px #ccc;
      padding: 4px 8px;
      text-align: left;
    }
  }
  hr {
    border: solid 1px #eee;
    margin: 30px 0;
  }
`;

export const SectionChartGrid = styled.div<{ layout: ReportLayout; isFirstPage?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 3em;
  margin-top: ${(props) => (props.isFirstPage ? "2.5rem" : "0")};
  justify-content: center;
  page-break-inside: avoid;
  flex-grow: ${(props) => (props.layout === "print" && props.isFirstPage ? "1" : "auto")};
`;

export const ChartContainerWrapper = styled.div<{ spanRow: boolean; layout: ReportLayout }>`
  /* max-width: 100%;
  overflow-x: auto; */
  width: ${(props) => (props.spanRow ? "100%" : "calc(50% - 3em)")};
  min-width: ${(props) => (props.spanRow ? "600px" : "420px")};

  /* display: ${(props) => (props.layout === "screen" ? "block" : "flex")};
  flex-direction: ${(props) => (props.layout === "screen" ? "auto" : "column")};
  justify-content: ${(props) => (props.layout === "screen" ? "auto" : "flex-start")}; */

  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

export const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  right: 0;
  color: #999;
  font-size: 0.9em;
  text-align: right;
  padding: 1.2rem 2rem;
  background-color: rgba(255, 255, 255, 0.3);

  &::after {
    counter-increment: page;
    content: " | " counter(page);
  }
`;

export const SectionWidgetGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 2.5rem;
  justify-content: center;
  flex-grow: auto;
`;
