"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChartTooltipWrapper = exports.ChartTooltipTitle = exports.ChartTooltipBody = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _templateObject, _templateObject2, _templateObject3;
const ChartTooltipWrapper = _styled.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  position: absolute;\n  width: fit-content;\n  background: white;\n  border: white 1px solid;\n  opacity: 0;\n  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);\n  border-radius: 0.5em;\n  overflow: hidden;\n  z-index: 1;\n\n  &.visible {\n    opacity: 1;\n  }\n"])));
exports.ChartTooltipWrapper = ChartTooltipWrapper;
const ChartTooltipTitle = _styled.default.h5(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  background: ", " ;\n  font-weight: bold;\n  color: #fff;\n  font-size: 1.1rem;\n  padding: 0.1em 1em;\n  margin: 0;\n  white-space: nowrap;\n"])), props => props.color || "#00316E");
exports.ChartTooltipTitle = ChartTooltipTitle;
const ChartTooltipBody = _styled.default.div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  background: white;\n  padding: 0.1em 1em;\n\n  em {\n    font-weight: bold;\n    font-style: normal;\n  }\n"])));
exports.ChartTooltipBody = ChartTooltipBody;