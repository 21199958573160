"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HorizontalBarChartGroup = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _templateObject;
const HorizontalBarChartGroup = _styled.default.g(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  .bar-label {\n    color: #333;\n    font-family: inherit;\n    font-weight: 600;\n    font-size: clamp(0.7rem, 0.9vw, 0.9rem);\n\n    .small-label & {\n      font-size: clamp(0.6rem, 0.8vw, 0.8rem);\n    }\n  }\n\n  .semitransparent {\n    opacity: 0.2;\n  }\n\n  .bar-group {\n    text {\n      transition: opacity 0.4s;\n      transition-delay: var(--animation-delay);\n      opacity: 0;\n    }\n  }\n\n  .bar-group.full {\n    text {\n      opacity: 1;\n    }\n  }\n"])));
exports.HorizontalBarChartGroup = HorizontalBarChartGroup;