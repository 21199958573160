"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChartWrapper = exports.ChartTitle = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _templateObject, _templateObject2;
const ChartWrapper = _styled.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  page-break-inside: avoid;\n  width: 100%;\n\n  .x-axis, y-axis {\n    font-size: clamp(0.75rem, 1.1vw, 0.9rem);\n    text {\n      fill: #666;\n      font-family: \"Work Sans\", sans-serif;\n    }\n  }\n\n"])));
exports.ChartWrapper = ChartWrapper;
const ChartTitle = _styled.default.h4(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  font-size: 1.5rem;\n  color: ", ";\n  font-weight: 500;\n  letter-spacing: 0.5px;\n  text-align: center;\n  margin: 0;\n  margin-bottom: 16px;\n  min-height: ", ";\n"])), props => props.color, props => props.layout === "print" ? "64px" : "0");
exports.ChartTitle = ChartTitle;