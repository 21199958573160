import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import type { GapFundingCalculatorProps } from "./GapFundingCalculator";
import { formatNumber } from "./util";
import type { ThemeData } from "@api/reports/output/output.types";
import { ChartWrapper, ChartTitle } from "@hra/hna-charts";
import type { WebReportTheme } from "@api/projects/projects.types";

type ResultBarChartProps = {
  needsMet: number;
  needs: GapFundingCalculatorProps["widget"]["componentProps"]["needs"];
  colorScheme: ThemeData;
  theme: WebReportTheme;
};

const MARGIN = { top: 40, right: 150, bottom: 20, left: 20 };

export default function ResultShareChart({ needsMet, needs, colorScheme, theme }: ResultBarChartProps) {
  const svgRef = useRef<SVGSVGElement>(null);
  const width = 380;
  const height = 300;

  const boundsWidth = width - MARGIN.right - MARGIN.left;
  const boundsHeight = height - MARGIN.top - MARGIN.bottom;

  const needsGap = Math.max(needs.projected - needsMet, 0);

  const projected50 = Math.round(needs.projected * 0.5);
  const projected75 = Math.round(needs.projected * 0.75);

  const yScale = d3
    .scaleLinear()
    .domain([0, needsGap + needsMet])
    .range([boundsHeight, 0]);

  useEffect(() => {
    const chart = d3.select(svgRef.current);
    chart
      .selectAll("rect.need-met")
      .data([{ point: needsMet }])
      .transition()
      .duration(1200)
      .attr("y", (p) => {
        return yScale(p.point);
      })
      .attr("height", (p) => {
        return yScale(0) - yScale(p.point);
      });
  }, [height, yScale, needsMet]);

  return (
    <ChartWrapper spanRow={false}>
      <ChartTitle color={theme.colors.main} layout="screen">
        Share of Overall Need Met <br /> (At or Below 50% AMI)
      </ChartTitle>
      <svg
        ref={svgRef}
        width={width}
        height={height}
        style={{ background: "white", borderRadius: "8px", display: "block", margin: "auto" }}
      >
        <defs>
          <pattern id="dashes" height="6" width="6" patternUnits="userSpaceOnUse">
            <line x1="0" y1="4" x2="2" y2="4" strokeWidth="1" stroke="#aaa" />
          </pattern>
        </defs>
        <g width={boundsWidth} height={boundsHeight} transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}>
          <rect
            x={boundsWidth * 0.1}
            y={yScale(needsGap + needsMet)}
            height={boundsHeight}
            width={boundsWidth * 0.7}
            fill="url(#dashes)"
          />
          <rect
            x={boundsWidth * 0.1}
            y={yScale(0)}
            height={0}
            width={boundsWidth * 0.7}
            fill={colorScheme.colors.schemes.categorical[0]}
            className="need-met"
          />
          <text
            fill="#ffffff"
            x={boundsWidth * 0.45}
            y={yScale(needsMet / 2) - 10}
            textAnchor="middle"
            fontSize={14}
            stroke={colorScheme.colors.schemes.categorical[0]}
            strokeWidth={3}
            paintOrder={"stroke"}
            strokeLinecap="butt"
            strokeLinejoin="miter"
          >
            Need Met
          </text>
          <text
            fill="#ffffff"
            x={boundsWidth * 0.45}
            y={yScale(needsMet / 2) + 10}
            textAnchor="middle"
            fontSize={16}
            fontWeight={"bold"}
            stroke={colorScheme.colors.schemes.categorical[0]}
            strokeWidth={3}
            paintOrder={"stroke"}
            strokeLinecap="butt"
            strokeLinejoin="miter"
          >
            {formatNumber(needsMet)}
          </text>
          <line
            x1={0}
            y1={yScale(needs.current)}
            x2={boundsWidth * 0.9}
            y2={yScale(needs.current)}
            strokeWidth="1"
            stroke="#222222"
            strokeDasharray={"5 3"}
          ></line>
          <text
            fill={theme.colors.main}
            x={boundsWidth * 0.9 + 5}
            y={yScale(needs.current) + 5}
            textAnchor="start"
            fontSize={14}
          >
            Current Need
          </text>
          <text
            fill={theme.colors.main}
            x={boundsWidth * 0.9 + 5}
            y={yScale(needs.current) + 25}
            textAnchor="start"
            fontSize={16}
            fontWeight={"bold"}
          >
            {formatNumber(needs.current)}
          </text>
          {yScale(needs.current) > yScale(needsMet) && (
            <line
              x1={boundsWidth * 0.1}
              y1={yScale(needs.current)}
              x2={boundsWidth * 0.8}
              y2={yScale(needs.current)}
              strokeWidth="1"
              stroke="#ffffff"
              strokeDasharray={"5 3"}
            ></line>
          )}
          <line
            x1={0}
            y1={yScale(needs.projected)}
            x2={boundsWidth * 0.9}
            y2={yScale(needs.projected)}
            strokeWidth="1"
            stroke="#222222"
            strokeDasharray={"5 3"}
          ></line>
          <text fill="#333" x={boundsWidth * 0.9 + 5} y={yScale(needs.projected) + 5} textAnchor="start" fontSize={14}>
            Projected Need (2030)
          </text>
          <text
            fill="#333"
            x={boundsWidth * 0.9 + 5}
            y={yScale(needs.projected) + 25}
            textAnchor="start"
            fontSize={16}
            fontWeight={"bold"}
          >
            {formatNumber(needs.projected)}
          </text>

          <line
            x1={0}
            y1={yScale(projected50)}
            x2={boundsWidth * 0.9}
            y2={yScale(projected50)}
            strokeWidth="1"
            stroke="#777"
            strokeDasharray={"5 3"}
          ></line>
          <text fill="#333" x={boundsWidth * 0.9 + 5} y={yScale(projected50) + 5} textAnchor="start" fontSize={14}>
            50% Projected Need
          </text>

          <line
            x1={0}
            y1={yScale(projected75)}
            x2={boundsWidth * 0.9}
            y2={yScale(projected75)}
            strokeWidth="1"
            stroke="#777"
            strokeDasharray={"5 3"}
          ></line>
          <text fill="#333" x={boundsWidth * 0.9 + 5} y={yScale(projected75) + 5} textAnchor="start" fontSize={14}>
            75% Projected Need
          </text>
        </g>
      </svg>
    </ChartWrapper>
  );
}
