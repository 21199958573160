"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAxisOverlap = useAxisOverlap;
var _react = require("react");
var d3 = _interopRequireWildcard(require("d3"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function useAxisOverlap(svgRef) {
  let xAxisSelector = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ".x-axis";
  const [labelOverlap, setLabelOverlap] = (0, _react.useState)(false);
  const detectLabelOverlap = (0, _react.useCallback)(() => {
    if (svgRef.current === null) {
      return;
    }
    let overlap = false;
    let prev = null;
    const ticks = svgRef.current.querySelectorAll("".concat(xAxisSelector, " .tick"));
    for (const tick of ticks) {
      const matches = tick.getAttribute("transform").match(/translate\((-?[0-9\.]+),.*\)/);
      const width = tick.getBBox().width;
      const curr = [Number(matches[1]), width + Number(matches[1])];
      if (prev && curr[0] < prev[1] + 2 && curr[0] !== prev[0]) {
        overlap = true;
        break;
      }
      prev = curr;
    }
    if (labelOverlap) {
      const texts = svgRef.current.querySelectorAll("".concat(xAxisSelector, " text"));
      for (const text of texts) {
        d3.select(text).attr("text-anchor", "end").attr("transform", "rotate(-24)").attr("style", "transform-origin: 0 ".concat(text.getAttribute("y"), "px;"));
      }
      // Check for overlapping after rotation and remove ticks if overlap persist
      let prev = null;
      const ticks = svgRef.current.querySelectorAll("".concat(xAxisSelector, " .tick"));
      for (const tick of ticks) {
        const matches = tick.getAttribute("transform").match(/translate\((-?[0-9\.]+),.*\)/);
        const width = tick.getBBox().width;
        const curr = [Number(matches[1]), width + Number(matches[1])];
        if (prev && curr[0] < prev[1] - 28 && curr[0] !== prev[0]) {
          tick.remove();
        } else {
          prev = curr;
        }
      }
    } else {
      d3.select(svgRef.current).select(xAxisSelector).selectAll("text").attr("text-anchor", "middle").attr("transform", "rotate(0) translate(0, 0)");
    }
    if (!labelOverlap && overlap) {
      setLabelOverlap(overlap);
    }
  }, [labelOverlap, svgRef.current]);
  return {
    detectLabelOverlap,
    labelOverlap
  };
}