import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ReportLayout } from "./types";
import { parseStringToArray } from "./utils";
import PrintReport from "./PrintReport";
import ScreenReport from "./ScreenReport";
import { WebReportConfiguration } from "@api/projects/projects.types";

type ReportProps = {
  layout: ReportLayout;
};

export type FetchPageBody = {
  customGeoIds?: string[];
  exclude?: string[];
  selection?: string[];
  filters?: string[];
};

export const ThemeContext = React.createContext<WebReportConfiguration["theme"] | null>(null);

function Report({ layout }: ReportProps) {
  const { geoId, reportId, pageId } = useParams();
  // TODO: Search params shouldn't be passed as props
  const [searchParams] = useSearchParams();

  const custom: string[] = searchParams.get("custom")?.split(",") || [];
  const exclude: string[] = searchParams.get("exclude")?.split(",") || [];
  const filters: string[] = parseStringToArray(searchParams.get("filters")) || [];

  if (layout === "print") {
    return <PrintReport reportId={reportId} geoId={geoId} customGeoIds={custom} exclude={exclude} filters={filters} />;
  } else if (layout === "screen") {
    return (
      <ScreenReport
        reportId={reportId}
        geoId={geoId}
        custom={custom}
        exclude={exclude}
        filters={filters}
        pageId={pageId}
        baseUrl={`/report/${reportId}/${geoId}`}
      />
    );
  }
}

export default Report;
