"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChartDescriptionWrap = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _templateObject;
const ChartDescriptionWrap = _styled.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  margin-top: ", ";\n  margin-bottom: ", ";\n  color: ", ";\n  text-align: ", ";\n  font-size: ", ";\n  line-height: ", ";\n\n  p {\n    font-weight: 300;\n  }\n  strong {\n    font-weight: 500;\n  }\n  em {\n    font-weight: 400;\n    font-style: normal;\n  }\n\n"])), props => props.centered ? "2em" : "0.8em", props => props.centered ? "2.2em" : "1.4em", props => props.error ? "red" : "inherit", props => props.centered ? "center" : "left", props => !props.size || props.size === "medium" ? "0.9rem" : props.size === "large" ? "2.5rem" : "0.8rem", props => !props.size || props.size === "medium" ? "1.5rem" : props.size === "large" ? "1.2rem" : "1.2rem");
exports.ChartDescriptionWrap = ChartDescriptionWrap;