import styled from "@emotion/styled";

export const PrintLayout = styled.div`
  counter-reset: page;
`;

export const PrintCover = styled.section<{ color: string }>`
  color: ${(props) => props.color};
  max-width: 1280px;
  margin: auto;
  color: #fff;
  height: 816px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  page-break-inside: avoid;
  page-break-before: always;
  page-break-after: always;
  counter-increment: page;
  position: relative;
  gap: 1.2rem;

  h1 {
    color: ${(props) => props.color};
    font-weight: 500;
    text-align: center;
    font-size: 2.1em;
  }

  h2 {
    width: 100%;
    color: ${(props) => props.color};
    font-size: 4.3em;
    text-align: center;
  }
`;

export const CoverLogo = styled.div<{ color: string }>`
  position: absolute;
  top: 0;
  padding: 1.5rem;
  width: 100%;
  text-align: center;
  background-color: ${(props) => props.color};

  img {
    height: 5em;
    object-fit: contain;
    margin: auto;
    display: block;
  }
`;

export const CoverFooter = styled.div<{ color: string }>`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  background-color: ${(props) => props.color};
  color: white;
  min-height: 70px;
`;

export const PdfExtraPage = styled.section<{ color: string }>`
  position: relative;
  border-top: ${(props) => props.color} 1em solid;
  max-width: 1280px;
  margin: auto;
  color: #fff;
  height: 816px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5em;
  > img {
    max-width: 100%;
    max-height: 100%;
  }
`;
