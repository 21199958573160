"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QuantitativeFormatter = void 0;
const QuantitativeFormatter = {
  thousands: label => {
    const formatter = Intl.NumberFormat("en-US", {
      notation: "compact"
    });
    return formatter.format(Number(label));
  },
  money: label => Number(label).toLocaleString("en-US", {
    currency: "USD",
    style: "currency",
    maximumFractionDigits: 0
  }),
  money_thousands: label => {
    const formatter = Intl.NumberFormat("en-US", {
      notation: "compact"
    });
    return "$" + formatter.format(Number(label));
  },
  number: label => Number(label).toLocaleString("en-US"),
  number_signed: label => (Number(label) >= 0 ? "+" : "") + Number(label).toLocaleString("en-US"),
  percent: label => Number(label).toFixed(1) + "%",
  percent_diff: label => {
    const change = Number(label) - 100;
    return (change > 0 ? "+" : "") + change.toFixed(1) + "%";
  },
  percent_signed: label => (Number(label) >= 0 ? "+" : "") + Number(label).toFixed(1) + "%",
  hide: label => "",
  none: label => label.toString()
};
exports.QuantitativeFormatter = QuantitativeFormatter;