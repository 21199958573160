import React from "react";
import type { ReportData, ReportSkeleton } from "@api/reports/output/output.types";
import {
  Downloading,
  DrawerLogo,
  HeaderContent,
  HeaderGroup,
  HeaderLogo,
  HeaderNav,
  MainHeader,
  MainHeaderWrapper,
} from "./ScreenHeader.styled";
import { Link, useNavigate } from "react-router-dom";
import { Burger, Drawer, Group, Loader, Menu, createStyles, rem } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { useApi } from "../../../hooks/useApi";

type ScreenHeaderProps = {
  report: ReportData | ReportSkeleton;
  custom: string[];
  exclude: string[];
  filters: string[];
  headerRef: React.MutableRefObject<HTMLDivElement>;
  geoId: string;
  isLoading: boolean;
  pageId?: string;
  baseUrl: string;
};

type MenuLink = {
  pageId?: string;
  link: string;
  label: string;
  links?: {
    link: string;
    label: string;
    onClick?: () => void;
  }[];
};

const useStyles = createStyles((theme) => ({
  inner: {
    height: rem(56),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    position: "relative",
    display: "block",
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color: theme.white,
    fontWeight: 500,
    fontSize: "1rem",

    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },

    "&.active": {
      backgroundColor: theme.white,
      color: theme.colors["report"]?.[0],
    },

    [theme.fn.smallerThan("sm")]: {
      color: theme.colors["report"]?.[0],
      fontSize: "1.1rem",
      padding: "1rem",
      marginTop: "1rem",
    },
  },

  linkNav: {
    color: theme.colors["report"]?.[0],
  },

  linkLabel: {
    marginRight: rem(5),
  },

  dropdown: {
    [theme.fn.smallerThan("sm")]: {
      minWidth: "290px",
    },
  },
}));

type NavItemsProps = {
  links: MenuLink[];
  downloading: boolean;
  pageId: string;
  onLinkClick: () => void;
};

const NavItems = ({ links, downloading, pageId, onLinkClick }: NavItemsProps) => {
  const { classes } = useStyles();
  return (
    <>
      {links.map((link) => {
        const menuItems = link.links?.map((item) => (
          <Menu.Item key={item.link} onClick={item.onClick}>
            {item.label}
          </Menu.Item>
        ));

        if (menuItems) {
          return (
            <Menu
              key={link.label}
              trigger="hover"
              transitionProps={{ exitDuration: 0 }}
              withinPortal
              disabled={downloading}
            >
              <Menu.Target>
                <a href={link.link} className={classes.link} onClick={(event) => event.preventDefault()}>
                  <Group align="center" spacing={2} noWrap>
                    <span>{link.label}</span>
                    <IconChevronDown size="0.9rem" stroke={1.5} />
                  </Group>
                  {downloading && (
                    <Downloading>
                      <Loader color="white" mr={4} size={12} variant="bars" /> Please wait...
                    </Downloading>
                  )}
                </a>
              </Menu.Target>
              <Menu.Dropdown className={classes.dropdown}>{menuItems}</Menu.Dropdown>
            </Menu>
          );
        }

        return (
          <Link
            key={link.label}
            to={link.link}
            className={`${classes.link} ${link.pageId === pageId && "active"}`}
            onClick={() => onLinkClick()}
          >
            {link.label}
          </Link>
        );
      })}
    </>
  );
};

type FetchPageQuery = {
  custom?: string[];
  exclude?: string[];
  filters?: string[];
};

export default function ScreenHeader({
  report,
  headerRef,
  geoId,
  pageId,
  custom,
  exclude,
  filters,
  isLoading,
  baseUrl,
}: ScreenHeaderProps) {
  const [opened, { toggle, close }] = useDisclosure(false);
  const navigate = useNavigate();
  const { classes } = useStyles();

  const { loading: downloading, callApi: downloadFormat } = useApi<
    ReportData,
    { reportId: string; geoId: string; format: "pdf" | "excel" },
    FetchPageQuery
  >("/reports/:reportId/output/:geoId/:format", "post");

  const handleDownload = async (format: "pdf" | "excel") => {
    if (isLoading) {
      return;
    }
    await downloadFormat({ params: { reportId: report.id, geoId, format }, query: { custom, exclude, filters } });
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/reports/${report.id}/output/${geoId}/${format}`;
  };

  if (!report) {
    return <MainHeaderWrapper ref={headerRef} color={undefined} withNav={false}></MainHeaderWrapper>;
  }

  const links: MenuLink[] = [];

  if (report.pages.length > 1) {
    report.pages.forEach((page) => {
      links.push({
        // TODO: searchParams
        pageId: page.id,
        link: `${baseUrl}/${page.id}`,
        label: page.name,
      });
    });
  }

  const downloads = report.project.app.capabilities.downloads;
  let downloadLink: MenuLink | null = null;
  if (Object.values(downloads).some(Boolean)) {
    const downloadLabel = {
      pdf: "PDF Report",
      excel: "Raw Data (Excel)",
    };
    downloadLink = {
      link: `#download`,
      label: "Download",
      links: Object.keys(downloads)
        .filter((key) => downloads[key])
        .map((key) => ({
          link: `#${key}`,
          label: downloadLabel[key],
          onClick: () => handleDownload(key as "pdf" | "excel"),
        })),
    };
  }

  let topBarNav = null;
  let bottomBarNav = null;

  if (links.length < 3) {
    if (downloadLink) {
      links.push(downloadLink);
    }
    if (links.length > 0) {
      topBarNav = <NavItems links={links} downloading={downloading} pageId={pageId} onLinkClick={close} />;
    }
  } else {
    if (downloadLink) {
      topBarNav = <NavItems links={[downloadLink]} downloading={downloading} pageId={pageId} onLinkClick={() => {}} />;
    }
    bottomBarNav = <NavItems links={links} downloading={downloading} pageId={pageId} onLinkClick={close} />;
  }

  const header = {
    geography_name: report.geography.fullName || report.geography.name,
    report_title: report.title,
    none: "",
  };

  const theme = report.project.app.configuration.theme;
  const title = theme ? header[theme.header.title] : "";
  const subtitle = theme ? header[theme.header.subtitle] : "";

  return (
    <>
      {(bottomBarNav || topBarNav) && (
        <Drawer
          opened={opened}
          onClose={toggle}
          title={<DrawerLogo>{theme.logo && <img src={`${theme.logo}`} alt="HR&amp;A"></img>}</DrawerLogo>}
          size="xs"
          withCloseButton={false}
          styles={{
            content: {
              backgroundColor: "#f0f4f9",
            },
            header: {
              background: theme.colors.main,
            },
            body: {
              padding: 0,
            },
          }}
        >
          {bottomBarNav}
          {topBarNav}
        </Drawer>
      )}
      <MainHeaderWrapper ref={headerRef} color={theme.colors.main} withNav={bottomBarNav !== null}>
        <MainHeader>
          <HeaderLogo onClick={() => navigate(baseUrl === "/page" ? "/" : baseUrl)}>
            {theme.logo && <img src={`${theme.logo}`} alt="HR&amp;A"></img>}
          </HeaderLogo>
          <HeaderContent>
            {title && <h1>{title}</h1>}
            {subtitle && <h2>{subtitle}</h2>}
          </HeaderContent>
          <Group spacing={"sm"} className={classes.links} mr={20}>
            {topBarNav}
          </Group>
          {(bottomBarNav || topBarNav) && (
            <Burger opened={opened} onClick={toggle} className={classes.burger} size="md" color="#fff" mr={12} />
          )}
        </MainHeader>
      </MainHeaderWrapper>
      {bottomBarNav && (
        <HeaderNav>
          <HeaderGroup spacing={"sm"} className={classes.links} mr={20} position="right">
            {bottomBarNav}
          </HeaderGroup>
        </HeaderNav>
      )}
    </>
  );
}
