import styled from "@emotion/styled";

export const ScreenLayout = styled.div`
  --nav-width: 18.5em;
  --nav-margin: 3.5em;
  --content-max-width: 1280px;
  --main-container-width: calc(var(--nav-width) + var(--nav-margin) + var(--content-max-width));
  --content-padding: 1.5em;
  --header-height: 6rem;
`;

export const MainContainer = styled.div<{ hasNav: boolean }>`
  padding-top: ${(props) => (props.hasNav ? "8.5em" : "6em")};
  padding-left: calc(var(--nav-width) + var(--nav-margin));
  padding-right: 1em;
  margin: auto;
  max-width: var(--main-container-width);

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 8em var(--content-padding) 0 var(--content-padding);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: var(--content-padding);
  }
`;

export const DraftAlert = styled.div`
  background: #fff1c5;
  color: #926904;
  padding: 1.5rem;
  text-align: center;
  margin-top: 3rem;
  border-radius: 0.5rem;
`;

export const LoadingScreen = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 30em);
`;

export const AsideZone = styled.aside<{ hasTopBar: boolean }>`
  width: var(--nav-width);
  position: fixed;
  top: ${(props) => (props.hasTopBar ? "11.5em" : "9em")};
  left: max(calc((100% - var(--main-container-width)) / 2), var(--content-padding));

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;
