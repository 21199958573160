import React, { useLayoutEffect, useRef } from "react";
import { ChapterWrapper } from "./Chapter.styled";
import type { ChapterData, ReportData } from "@api/reports/output/output.types";
import ScreenSection from "./ScreenSection";
import { sectionHasContent } from "../utils";
import type { WebReportConfiguration } from "@api/projects/projects.types";

type ReportProps = {
  chapter: ChapterData;
  report: ReportData;
  onSectionChange: (sectionId: string) => void;
  customGeoIds?: string[];
  theme: WebReportConfiguration["theme"];
};

function ScreenChapter({ chapter, onSectionChange, report, customGeoIds, theme }: ReportProps) {
  const chapterRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    chapterRef.current?.querySelectorAll('a[href^="#"]').forEach((a) => {
      a.addEventListener("click", (e) => {
        const target = e.currentTarget as HTMLAnchorElement;
        e.preventDefault();
        const targetId = target.getAttribute("href").substring(1); // Remove the '#' symbol
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          const offsetPosition = targetElement.offsetTop;
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      });
    });
  }, [chapterRef]);

  return (
    <ChapterWrapper ref={chapterRef} id={chapter.id}>
      {chapter.sections.filter(sectionHasContent("screen")).map((section) => (
        <ScreenSection
          section={section}
          key={section.id}
          onSectionChange={onSectionChange}
          report={report}
          customGeoIds={customGeoIds}
          theme={theme}
        ></ScreenSection>
      ))}
    </ChapterWrapper>
  );
}

export default React.memo(ScreenChapter);
